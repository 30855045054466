// import './index.scss'
//import SplitType from 'split-type'

export default class {
	constructor(el, device) {
		this.DOM = {
			el: el,
		}
		this.device = device
		this.active = 0

		this.create()
	}

	async create() {
		this.anim = gsap.timeline({paused: true}).fromTo(this.DOM.el.parentNode, {opacity: 0}, {opacity: 1, duration: 0.6, ease: 'power2.inOut'}, 0).fromTo(
			this.DOM.el.querySelector('.grad'),
			{
				'--gradop': '0',
				'--gradsize': '0%',
			},
			{
				'--gradop': '.2',
				'--gradsize': '50%',
				duration: 2,
				ease: 'power4.easeInOut',
			},
			0.6
		)
		// .fromTo(this.DOM.el.querySelector('.error_intro_bg img'), {left: 100 + '%', top: -50 + '%', scale: 4}, {left: -100 + '%', top: 0 + '%', scale: 0, duration: 2, ease: 'power2.inOut'}, 0.6)

		// .fromTo(this.DOM.el.querySelector('.error_intro_bg img'), {left: 0 + '%', top: -20 + '%', scale: 2}, {left: -100 + '%', top: 0 + '%', scale: 0, duration: 1, ease: 'power2.inOut'}, 0.6)
		this.animbird = gsap
			.timeline({paused: true})
			.to(this.DOM.el.querySelector('.error_intro_bg img'), {translateY: 0 + '%', translateX: 0 + '%', opacity: 0.8, duration: 0.6, ease: 'none'}, 1)
			.to(this.DOM.el.querySelector('.error_intro_bg img'), {translateY: 30 + '%', translateX: -120 + '%', opacity: 0.3, duration: 0.6, ease: 'none'}, 1.6)
	}

	async start() {
		this.anim.play()
		this.animbird.play()
	}
	initEvents() {}

	removeEvents() {}

	onResize() {}
}
