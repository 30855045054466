

export default class {
  constructor (el,device,touch,animev) {


    this.DOM = {
      el:el,
      card:el.querySelector('.cnt_card'),
      gradfirst:el.querySelector('.gradfirst'),
      bg:el.querySelector('.stck_intro_bg'),
    }
    this.device = device
    this.touch = touch
    this.active = 0
    this.animev = animev

    this.cleaning()
    this.create()
    
  }

  async create(){
    
    const card = new Map()
    card.set('main',this.DOM.card)

   

    card.set('card',this.DOM.card.querySelector('.Acard'))
    card.set('hold',this.DOM.card.querySelector('.Acard_h'))
    card.set('inf',this.DOM.card.querySelector('.Acard_inf'))
    card.set('bg',this.DOM.card.querySelector('.Acard_grad'))
    card.set('im',this.DOM.card.querySelector('.Acard_ima'))
    card.set('ioim',this.DOM.card.querySelector('.Aimrev .iO-std'))
    card.set('iot',this.DOM.card.querySelector('.Aclipgrad .iO-std'))
    card.set('iobtn',this.DOM.card.querySelector('.iO-autoSpark'))



    let times = [
      //Grad 1
      0,
      //Grad 2
      1.6,
      //Entra grad
      4.6,
      //Entra card
      4.6
      
      
    ]

    //SETS


    this.anim = gsap.timeline({paused:true})
    .set(this.DOM.el,{opacity:1},0)


    if(this.device < 1){
    this.anim
      .fromTo(this.DOM.bg,
        {
          '--gradx':'100vw',
          '--gradsize':'50vh',
          '--grado':0,
        },
        {
          '--gradx':'-60vw',
          '--gradsize':'50vh',
          '--grado':1,
          ease:'power4.inOut',
          duration:2,
          onStart:()=>{
            this.animev.detail.state = 1
            this.animev.detail.params = [1,.6,90]
            for(let i=0;i<3;i++){
              this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[0].parentNode
              document.dispatchEvent(this.animev)

            }
          }
        }
      ,times[0])
      .fromTo(this.DOM.bg,
        {
          '--gradx':'50vw',
          '--gradsize':'50vh',
        },
        {
          '--gradx':'0vw',
          '--gradsize':'50vh',
          ease:'none',
          duration:.4,
          immediateRender:false
        }
      ,times[1])
      .fromTo(this.DOM.bg,
        {
          '--gradx':'0vw',
          '--gradsize':'50vh',
          '--grado':1,
        },
        {
          '--gradx':'80vw',
          '--gradsize':'45vh',
          '--grado':0,
          ease:'none',
          duration:.6,
          immediateRender:false
        }
      ,times[1]+1)

      .fromTo(this.DOM.bg,
        {
          '--grady':'-100vh',
        },
        {
          '--grady':'100vh',
          ease:'power4.inOut',
          duration:2,
          immediateRender:false,
          onStart:()=>{
            
            
            this.animev.detail.state = -1
            this.animev.detail.params = [1,.4,90]
            for(let i=0;i<3;i++){
              this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[0].parentNode
              document.dispatchEvent(this.animev)

            }

            this.animev.detail.state = 1
            this.animev.detail.params = [1,.7,-90]
            for(let i=0;i<3;i++){
              this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[1].parentNode
              document.dispatchEvent(this.animev)

            }


          },

          
        }
      ,times[1])
      .fromTo(this.DOM.bg,
        {
          '--gradx':'25%',
          '--grady':'-100%',
          '--gradsize':'24vw',
          '--grado':1,
        },
        {
          '--gradx':'20%',
          '--grady':'100%',
          '--gradsize':'30vw',
          '--grado':0,
          ease:'power4.inOut',
          duration:2,
          immediateRender:false,
        onStart:()=>{
          this.animev.detail.state = -1
          for(let i=0;i<3;i++){
            this.animev.detail.params = [1,.6 + (i*.05),180]
            this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[1].parentNode
            document.dispatchEvent(this.animev)
    
          }
        }
      
      },
      times[2]-1)
      .fromTo(this.DOM.gradfirst,
        {'--gradsize':'0%'}
        ,{'--gradsize':'50%',immediateRender:false,duration:.6,
        
      
      },
      times[2])
      .fromTo(this.DOM.gradfirst,
        {
          '--gradop':'0',
        }
      ,{
        '--gradop':'.3',immediateRender:false,duration:.3},
      times[2])
      .fromTo(card.get('card'),
        {
          opacity:0,
        },
        {
          opacity:1,
          ease:'power2.inOut',
          duration:1,
        }
      ,times[3])
      .fromTo(card.get('card'),
      {

        '--blur':14+'px',
        scale:.6,
        yPercent:150,
        
        rotateY:12+'deg',
        rotateX:90+'deg',
      },
      {
        '--blur':0+'px',
        scale:1,
        yPercent:0,
        rotateY:0+'deg',
        rotateX:0+'deg',
        opacity:1,
        ease:'power2.inOut',
        duration:1.6,
      
        onComplete:()=>{
          gsap.set(card.get('card'),{'--blur':''})

        }
      }
    ,times[3]+.3)
    .set(card.get('inf'),{display:'none',immediateRender:false},times[0]+1.3)
    .set(card.get('inf'),{display:'block',immediateRender:false},'>')
    .fromTo(card.get('im'),
    {
      opacity:0,
    },

    {
      opacity:1,
      ease:'power2.inOut',
      duration:.3,
    },times[3]+.7)
    .fromTo(card.get('inf'),{
      opacity:0,
    },
    {
      opacity:1,
      ease:'power2.inOut',
      duration:.3,
      onStart:()=>{
        document.querySelector('.stck_intro').remove()
        card.get('inf').classList.add('act')
      },
      onComplete:()=>{
        gsap.set(card.get('card'),{blur:''})
        gsap.set(card.get('iobtn'),{display:'block',immediateRender:false})


        gsap.set(card.get('ioim'),{display:'block',immediateRender:false})
        gsap.set(card.get('iot'),{display:'block',immediateRender:false})
        

      }
    }
  ,times[3]+1)

  }
  else{

    this.anim
      .fromTo(this.DOM.bg,
        {
          '--gradx':'-20vw',
          '--grady':'0vh',
          '--gradsize':'80vw',
          '--grado':0,
        },
        {
          '--gradx':'-20vw',
          '--grady':'150vh',
          '--gradsize':'80vw',
          '--grado':1,
          ease:'power4.inOut',
          duration:2,
          onStart:()=>{
            this.animev.detail.state = 1
            this.animev.detail.params = [1,.6,0]
            for(let i=0;i<3;i++){
              this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[0].parentNode
              document.dispatchEvent(this.animev)

            }
          }
        }
      ,times[0])

      .fromTo(this.DOM.bg,
        {
          '--gradx':'-20vw',
          '--grady':'150vh',
          '--gradsize':'80vw',
          '--grado':0,
        },
        {
          '--gradx':'-20vw',
          '--grady':'-50vh',
          '--gradsize':'80vw',
          '--grado':1,
          ease:'power4.inOut',
          duration:2,
          immediateRender:false,
          onStart:()=>{
            
            
            this.animev.detail.state = -1
            this.animev.detail.params = [1,.4,0]
            for(let i=0;i<3;i++){
              this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[0].parentNode
              document.dispatchEvent(this.animev)

            }

            this.animev.detail.state = 1
            this.animev.detail.params = [1,.7,180]
            for(let i=0;i<3;i++){
              this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[1].parentNode
              document.dispatchEvent(this.animev)

            }


          },

          
        }
      ,times[1])
      .fromTo(this.DOM.bg,
        {
          '--gradx':'-20vw',
          '--grady':'0vh',
          '--gradsize':'80vw',
          '--grado':0,
        },
        {
          '--gradx':'-20vw',
          '--grady':'150vh',
          '--gradsize':'80vw',
          '--grado':1,
          ease:'power4.inOut',
          duration:2,
          immediateRender:false,
        onStart:()=>{
          this.animev.detail.state = -1
          for(let i=0;i<3;i++){
            this.animev.detail.params = [1,.4 + (i*.05),180]
            this.animev.detail.el = this.slidestt[i].querySelectorAll('span')[1].parentNode
            document.dispatchEvent(this.animev)
    
          }
        }
      
      },
      times[2]-1)
      
      .fromTo(card.get('card'),
        {
          opacity:0,
        },
        {
          opacity:1,
          ease:'power2.inOut',
          duration:1,
        }
      ,times[3])
    .set(card.get('inf'),{display:'none',immediateRender:false},times[0]+1.3)
    .set(card.get('inf'),{display:'block',immediateRender:false},'>')
    .fromTo(card.get('im'),
    {
      opacity:0,
    },

    {
      opacity:1,
      ease:'power2.inOut',
      duration:.3,
    },times[3]+.7)
    .fromTo(card.get('inf'),{
      opacity:0,
    },
    {
      opacity:1,
      ease:'power2.inOut',
      duration:.3,
      onStart:()=>{
      },
      onComplete:()=>{
        document.querySelector('.stck_intro').remove()
        gsap.set(card.get('card'),{blur:''})
        gsap.set(card.get('iobtn'),{display:'block',immediateRender:false})


        gsap.set(card.get('ioim'),{display:'block',immediateRender:false})
        gsap.set(card.get('iot'),{display:'block',immediateRender:false})
        card.get('inf').classList.add('act')

      }
    }
  ,times[3]+1)



  }




  }

  cleaning(){

    //Esconder el title de anim
    if(this.DOM.card.querySelector('.Aclipgrad .iO-std')){
      gsap.set(this.DOM.card.querySelector('.Aclipgrad .iO-std'),{display:'none'})
    }
    // TITLE ANIM CLEAN

    this.slidestt = this.DOM.el.querySelectorAll('.stck_intro_el')
    
    for(let a of this.slidestt){
      let span= ''
      for(let [i,b] of a.querySelectorAll('span').entries()){
        // if(span == b.innerHTML){
        //   b.innerHTML = ''
        // }
        // else{
          span = b.innerHTML
          this.animev.detail.state = 0
          this.animev.detail.el = b.parentNode
          document.dispatchEvent(this.animev)
          if(i==0){ 
            a.style.width = b.clientWidth+'px'

          }

        // }
      }

    }

  }
  
  async start(val = 0){

    if(val == 1){
      this.anim.progress(.6)
    }
    await this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
