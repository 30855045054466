
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
   
    
    this.anim = gsap.timeline({paused:true})
  .fromTo(this.DOM.el.parentNode,{opacity:0},{opacity:1,duration:.6,ease:'power2.inOut'},0)
  .fromTo(this.DOM.el.querySelector('.grad'),
    {
    '--gradop':'0',
    '--gradsize':'0%',


    }
    ,{
    '--gradop':'.2',
    '--gradsize':'50%',
    duration:2,ease:'power4.easeInOut'},.6)
    

  
  }
  
  async start(){
    this.anim.play()

  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
