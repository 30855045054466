
export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      bggrad:el.querySelector('.contact_intro_bg')
    }

    this.active = 0
    this.create()
  }

  async create(){

    this.anim = gsap.timeline({paused:true})
    
    .fromTo(this.DOM.el,{opacity:0},{opacity:1,duration:.6,ease:'power2.inOut'},0)
    
    .fromTo(this.DOM.bggrad,
      {
      '--gradop':'0',
      '--gradsize':'0%',
      '--y':30+'%',


      }
      ,{
      '--gradop':'.35',
      '--gradsize':'50%',
      '--y':0+'%',
      duration:2,ease:'power2.inOut'},.6)
    
  
  }
  
  async start(){
    await window.timeout(600)
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
