
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch,animev) {

    this.pos = obj.pos
    this.device = device
    this.touch = touch

    this.animev = animev
    
    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode,
      card:obj.el.parentNode.querySelector('.cnt_card'),
      ima:obj.el.parentNode.querySelector('.cnt_ima'),
      bggrad:obj.el.parentNode.querySelector('.gradfirst'),
      canvas:obj.el.parentNode.querySelector('.stars'),
      points:obj.el.parentNode.querySelectorAll('.cnt_points .point'),
      tabs:obj.el.parentNode.querySelectorAll('.cnt_tabs .el'),
      tabsc:obj.el.parentNode.querySelectorAll('.cnt_tabs .el_plus'),
      stat:obj.el.parentNode.querySelector('.cnt_stat'),
      statbg:obj.el.parentNode.querySelector('.cnt_stat_bg'),
      statgrad:obj.el.parentNode.querySelector('.cnt_stat_grad'),
      statels:obj.el.parentNode.querySelectorAll('.cnt_stat .el'),
      statim:obj.el.parentNode.querySelector('.cnt_stat_images'),
      statims:obj.el.parentNode.querySelectorAll('.cnt_stat .im'),
      claims:obj.el.parentNode.querySelector('.cnt_claims'),
      
    }




    this.active = 0
    this.isstarted = 0
    this.isworking = 0
    this.isupdate = 2
    this.time  = performance.now()

    this.h = window.innerHeight
    

    //STARS
    this.mouse = {x:0,y:0}
    this.circles = []
    this.dpr = window.devicePixelRatio || 1
    this.ctx = this.DOM.canvas.getContext('2d')

    //Stats
    this.statpos = -1
    

    this.create()
  }
  calcPoint(pos=null,state=0){
    if(state == 0){
      this.pointsm = 'vh'
      // this.pointsizes = [32.4,35.1,42,48.6]
      this.pointsizes = [31.1,28.8,43.3,37.5]
      this.statsim = [42.45,20]

      for(let [i,a] of this.DOM.points.entries()){


        this.DOM.points[i].style.setProperty('--d', this.pointsizes[i]+this.pointsm)



      }
    }
    if(pos == null){
      return false
    }

    let valr = 90 + (this.pointstates[pos]*this.pointrad[pos])
    this.DOM.points[pos].style.setProperty('--r', valr+'deg')
   
  }
  async create(){

    // MAIN ANIM
      //Titles
    this.autos = 
    {
      imC:0,
      imW:0,
      canvas:0,
      stars:1,
      nums:0,
      zoom:2,
      stat:0
    }

    this.pointrad = [200,256,324,424]
    this.pointstates = {0:0,1:0,2:0,3:0}
    this.statsim = [24.45,37]
    this.calcPoint(null,0)

    this.imsts = 
    {
      //0 es posición inicial, tarjeta a la iz
      w0:0,
      h0:0,
      x0:0,
      y0:0,
      //1 es posición rellenando tarjeta entera, tarjeta en el centro
      w1:0,
      h1:0,
      //2 es posición final, tarjeta en el centro
      x1:0,
      y1:0,
      
    }


    this.timectr = -1
    this.delay = 1000 * 4.8



    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      total:0,
    }


    const card = new Map()
    card.set('main',this.DOM.card)
    card.set('card',this.DOM.card.querySelector('.Acard'))
    card.set('hold',this.DOM.card.querySelector('.Acard_h'))
    card.set('inf',this.DOM.card.querySelector('.Acard_inf'))
    card.set('bg',this.DOM.card.querySelector('.Acard_grad'))
    card.set('im',this.DOM.card.querySelector('.Acard_ima'))
    card.set('ioim',this.DOM.card.querySelector('.Aimrev .iO-std'))
    card.set('iot',this.DOM.card.querySelector('.Aclipgrad .iO-std'))
    card.set('iobtn',this.DOM.card.querySelector('.iO-autoSpark'))

    card.get('ioim').parentNode.classList.add('ioesp')
    // gsap.set(card.get('ioim'),{display:'block'})

    const ima = new Map()
    ima.set('main',this.DOM.ima)
    ima.set('eye',this.DOM.ima.querySelector('.eye'))
    ima.set('bg',this.DOM.ima.querySelector('.bg'))
    // card.set('center',this.DOM.ima.querySelector('.center'))

    //CLAIMS
    const claims = new Map()
    claims.set('main',this.DOM.claims)
    claims.set('t1',this.DOM.claims.querySelector('.tt1'))
    claims.set('t2',this.DOM.claims.querySelector('.tt2'))

    
    //SCROLL ANIM
    const scr = this.DOM.parent.querySelector('.scrme_l')
    
    this.scranim = gsap.timeline({paused:true,repeat:-1,repeatDelay:1,
      
    })
    .fromTo(scr,{
      scaleY:0
    },{
      scaleY:1,
      duration:.6,
    },0)
    .fromTo(scr,{
      opacity:1,
      scaleY:1,
      transformOrigin:'50% 0%',
    },{
      opacity:0,
      scaleY:0,
      transformOrigin:'50% 100%',
      delay:.4,
      duration:1,
    },.6)


    //STAT ANIM

    let animobj = {
      x:0,
      y:50,
      o:0,
      sc:0,
      rot:0,
      rotneg:0,
      w:0
    }

    //MAIN ANIM

    this.anim = gsap.timeline({paused:true,
      onComplete:()=>{
        this.timectr = -1
      
      }
    })
    // FIRST STEP ( Se va la tarjeta, queda la imagen y crece )
    //200vh
    //4s
    .set(card.get('im'),{opacity:0,immediateRender:false},0)
    .set(ima.get('main'),{display:'block',immediateRender:false,
      onComplete:()=>{
        this.calcIm(0)
      }
    },0)
    .fromTo(this.autos,{imC:0},{imC:1,immediateRender:false,duration:4,
      onUpdate:()=>{
        
        this.calcIm(1)
      }
    },'>')
    .to(card.get('hold'),{opacity:0,y:-100+'vh',duration:1.3,immediateRender:false},0)
    .to(this.DOM.bggrad,{
      '--gradsize':'20%',
      '--gradop':'0',
      immediateRender:false,duration:.6,
    },0)
    .to(this.DOM.parent.querySelector('.cnt_points'),{'--sc':1,duration:.6,ease:'power2.inOut'},3.4)

    // SECOND STEP ( Entra claim y estrellas )
    //300 vh
    //6 s
    
    .fromTo(this.autos,{canvas:0,zoom:1},{canvas:1,zoom:0,duration:1,immediateRender:false,ease:'power2.inOut',
      onUpdate:()=>{
      this.DOM.canvas.style.opacity = this.autos.canvas
      }
    },4)
  .fromTo(claims.get('t1'),{opacity:0},{opacity:1,duration:.4},4)
  .fromTo(claims.get('t1'),{'--scale':.5,'--blur':4+'px'},{'--scale':1,'--blur':0+'px',duration:1.4},4)
  
  // THIRD STEP ( Se va 1 claim  Giro de puntos, y aparecen las tarjeta )
  //400 vh
  //8 s
  .fromTo(claims.get('t1'),{'--scale':1,'--blur':0+'px',opacity:1},{'--scale':1.4,'--blur':4+'px',opacity:0,duration:.2,immediateRender:false},5.4)
  



  .to(this.pointstates,
    {0:1,duration:.8,ease:'power2.inOut',
      onUpdate:()=>{
        this.calcPoint(0,1)
      }
    },5.4)
    .to(this.pointstates,
      {1:1,duration:1,ease:'power2.inOut',
      onUpdate:()=>{
        this.calcPoint(1,1)
  
      }
    },5.4)
    .to(this.pointstates,
      {2:1,duration:1.2,ease:'power2.inOut',
      onUpdate:()=>{
        this.calcPoint(2,1)
  
      }
    },5.4)
    .to(this.pointstates,
      {3:1,duration:1.4,ease:'power2.inOut',
      onUpdate:()=>{
        this.calcPoint(3,1)
  
      }
    },5.4)


    for(let [i,a] of this.DOM.tabs.entries()){
      a.classList.add('inac')
      this.anim.fromTo(a,{'--z':-400+'vw','--grad':0,opacity:0},
      {'--z':0+'vw','--grad':1,opacity:2,duration:.4,delay:.1*i,
        onStart:()=>{
          a.classList.add('inac')
          a.classList.remove('act')
        },
        onReverseComplete:()=>{
          a.classList.add('inac')
          a.classList.remove('act')
          
        },
        onUpdate:()=>{

          a.classList.add('inac')
  
        },
        onComplete:()=>{
          a.classList.remove('inac')
  
        },
      },'<')
    }
    this.anim
    .fromTo(claims.get('t2'),{opacity:0},{opacity:1,duration:.4},5.7)
    .fromTo(claims.get('t2'),{'--scale':.5,'--blur':4+'px'},{'--scale':1,'--blur':0+'px',duration:.6},5.7)
    // FOURTH STEP ( Se van los tabs, y entran los stats )
        //x vh
        //x s
    //OUT
    .fromTo(claims.get('t2'),{'--scale':1,'--blur':0+'px',opacity:1},{'--scale':1.4,'--blur':4+'px',opacity:0,duration:.4,immediateRender:false},6.7)
    .to(this.DOM.parent.querySelector('.cnt_points'),{'--sc':0,duration:.2,ease:'power2.inOut',immediateRender:false},6.7)
    for(let [i,a] of this.DOM.tabs.entries()){
      this.anim.fromTo(a,{'--z':0+'vw',opacity:2,'--blur':0+'px'},
      {'--z':100+'vw','--blur':14+'px',opacity:0,duration:.4,
       ease:'power4.inOut',
       immediateRender:false
      },6.7)

    }
    //IN
    this.anim
    .set(this.DOM.stat,{display:'none',immediateRender:false,
    onReverseComplete:()=>{
      if(this.statpos != -1){

        this.animev.detail.state = -1
        this.animev.detail.params = [0,0,90]
        this.animev.detail.el = this.DOM.statels[this.statpos]
        document.dispatchEvent(this.animev)
        
      }
    }
    },6.7)
    .set(this.DOM.stat,{display:'flex',immediateRender:false,},'>')
    .fromTo(this.DOM.stat,{opacity:0},
      {opacity:1,duration:.6,immediateRender:false,
        onStart:()=>{

          this.statpos = 0
          this.animev.detail.state = 1
          this.animev.detail.params = [.6,0,90]
          this.animev.detail.el = this.DOM.statels[0]
          document.dispatchEvent(this.animev)


        }
      },6.7)

    // .to(this.DOM.ima.querySelector('.line0'),{'--scale':1,duration:.4,ease:'power2.inOut',immediateRender:false},6.4)
    .to(this.DOM.ima.querySelector('.line1'),{opacity:0,'--scale':1.565,duration:.4,ease:'power2.inOut',immediateRender:false},6.7)
    .to(this.DOM.ima.querySelector('.line2'),{opacity:0,'--scale':2.76,duration:.4,ease:'power2.inOut',immediateRender:false},6.7)
    .to(this.DOM.ima.querySelector('.line3'),{opacity:0,'--scale':3.59,duration:.4,ease:'power2.inOut',immediateRender:false},6.7)

    .fromTo(animobj,
      {rot:0+'deg',rotneg:0+'deg',sc:0.6},
      {
      rotneg:-360+'deg',rot:360+'deg',
      sc:1,
      duration:.6,
      immediateRender:false,
      onUpdate:()=>{

        this.DOM.stat.style.setProperty('--mouse-o', animobj.o)
        this.DOM.stat.style.setProperty('--rotate', animobj.rot)
        this.DOM.stat.style.setProperty('--scale', animobj.sc)
        this.DOM.stat.style.setProperty('--rotateneg', animobj.rotneg)
        this.DOM.stat.style.setProperty('--w', animobj.w)


        this.DOM.statbg.style.setProperty('--mouse-o', animobj.o)
        this.DOM.statbg.style.setProperty('--scale', animobj.sc)
        this.DOM.statbg.style.setProperty('--rotate', animobj.rot)
        this.DOM.statbg.style.setProperty('--w', animobj.w)

      },
      onComplete:()=>{

      }
    },6.7)
    .fromTo(animobj,{o:0},{o:2,duration:.4},6.7)
    .fromTo(animobj,{w:0+'vh'},{w:42+'vh',duration:.4},6.7)
    .fromTo(animobj,{o:2},{o:0,duration:.4},7.1)
    .fromTo(animobj,{w:42+'vh'},{w:0+'vh',duration:.4},7.1)
    .fromTo(this.DOM.statbg,
    {opacity:0}
    ,{opacity:1,immediateRender:false,duration:.4},6.7)
    .fromTo(this.DOM.statgrad,
    {'--gradsize':'0%'}
    ,{'--gradsize':'50%',immediateRender:false,duration:.7},6.7)
    .fromTo(this.DOM.statgrad,
      {'--gradop':'0',
    }
    ,{
      '--gradop':'.6',immediateRender:false,duration:.4},7)
    .fromTo(this.autos,{stars:1},{stars:0,duration:1,immediateRender:false},5.4)
    .fromTo(this.autos,{nums:0},{nums:1,duration:.5,immediateRender:false},6.9)
    .set(this.autos,{stat:0,immediateRender:false},7.2)
    .set(this.autos,{stat:1,immediateRender:false,
      onComplete:()=>{
        // this.changeStat(-2)
      }
    },'>')
    .set(this.autos,{stat:1,immediateRender:false,
      onReverseComplete:()=>{

        this.DOM.parent.querySelector('.scrme').classList.add('act')
      
      },
      onComplete:()=>{
        // this.changeStat(-1)
        this.DOM.parent.querySelector('.scrme').classList.remove('act')
        
      }
    },7.4)
    .fromTo(this.DOM.stat.querySelector('.cnt_stat_ctr'),{opacity:0},
      {opacity:1,duration:.3,immediateRender:false,
       onReverseComplete:()=>{
        this.DOM.stat.querySelector('.cnt_stat_ctr').classList.remove('act')

       },
       onStart:()=>{
        this.DOM.stat.querySelector('.cnt_stat_ctr').classList.add('act')
       }
      },7.1)

    // STATS ANIM CLEAN
    for(let [i,a] of this.DOM.statels.entries()){
      if(a.querySelector('.t').innerHTML.length > 4){
        a.querySelector('.t').classList.add('t-s')
      }
      this.animev.detail.state = 0
      this.animev.detail.el = a
      document.dispatchEvent(this.animev)
      
    }


    for(let [i,a] of this.DOM.statims.entries()){
      if(i > 3){
        // a.remove()
        continue
      }

      gsap.set(a,{'--sc':0,'--b':0+'px','--o':0,'--r':0+'deg','--rn':0+'deg','--d':32+'vw'})


    }


    

    this.animst = gsap.timeline({
     
      onUpdate:()=>{

        this.DOM.stat.style.setProperty('--mouse-o', animobj.o)
        this.DOM.stat.style.setProperty('--rotate', animobj.rot)
        this.DOM.stat.style.setProperty('--rotateneg', animobj.rotneg)
        this.DOM.stat.style.setProperty('--w', animobj.w)

    }})
    //Animación del brillo ( se maneja con el update )
    .fromTo(animobj,{rot:0+'deg',rotneg:0+'deg'},{rotneg:-360+'deg',rot:360+'deg'},0)
    .fromTo(animobj,{o:0},{o:1},0)
    .fromTo(animobj,{w:0+'vh'},{w:42+'vh',},0)
    .fromTo(animobj,{w:42+'vh'},{w:0+'vh',},0)
    .fromTo(animobj,{o:1},{o:0},0)

    .set(this.DOM.statims[0],{'--sc':1,'--o':1,'--b':0+'px','--r':255+'deg','--rn':-255+'deg','--d':this.statsim[0]+this.pointsm},0)

    .set(this.DOM.statims[1],{'--sc':.8,'--o':1,'--b':0+'px','--r':80+'deg','--rn':-80+'deg','--d':this.statsim[0]+this.pointsm},0)

    .set(this.DOM.statims[2],{'--sc':.5,'--o':1,'--b':4+'px','--r':190+'deg','--rn':-190+'deg','--d':this.statsim[0]+this.pointsm},0)

    .set(this.DOM.statims[3],{'--sc':.4,'--o':1,'--b':8+'px','--r':340+'deg','--rn':-340+'deg','--d':this.statsim[1]+this.pointsm},0)

    

  }

  createAnim(){
    
  }

  killAnim(){

  }

  

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }
  
  start(){
    if(this.active==1){
      return false
    }
      this.startAnim()
      this.drawCircles()
      this.scranim.play()
      let time = performance.now()
      if(this.autos.stat == 1){

        this.timectr = performance.now() + this.delay

      }
    this.active = 1


  }
  stop(){
    // console.log('stop intro'+this.active)

    if(this.active==0){
      return false
    }

    this.scranim.pause()
    this.active = 0



    // this.killAnim()
    // this.anim.progress(0)
    // console.log('stop intro change'+this.active)

   

  }
  async startAnim(){

    this.DOM.parent.querySelector('.scrme').classList.add('act')
    this.scranim.play()



    // setTimeout(() => {
    //   this.anim.progress(1)
      
    // }, 200)
  }



  async calcIm(state=null){
    if(state == 0){


        let pos0 = this.DOM.card.querySelector('.Acard_fake').getBoundingClientRect()
        // let pos1 = this.DOM.card.querySelector('.Acard_h').getBoundingClientRect()
        let pos1 = this.DOM.card.querySelector('.Acard').getBoundingClientRect()

        this.imsts = 
        {
            w0:pos0.width,
            h0:pos0.height,
            x0:pos0.x,
            y0:pos0.y,
            w1:pos1.width,
            h1:pos1.height,
            x1:pos1.x,
            y1:pos1.y,
            
          }
        if(this.im0anim){
          this.im0anim.kill()
        } 
        
        this.im0anim = gsap.timeline({paused:true})
          .set(this.DOM.ima,{display:'block',borderRadius:'24px',x:this.imsts.x0+'px',y:this.imsts.y0+'px',width:this.imsts.w0+'px',height:this.imsts.h0+'px'},0)
          //Paso a tamaño imagen más centro ( tiene que ser el x w*-.5  )
          
          .to(this.DOM.ima,{x:((window.innerWidth *.5) - (this.imsts.w0 * .5))+'px',y:this.imsts.y1+'px',duration:.6},'>')

          .to(this.DOM.ima,{x:0+'px',y:0+'px',width:window.innerWidth+'px',height:window.innerHeight+'px',duration:1},1)
          .to(this.DOM.ima.querySelector('.bg'),{'--op':1,opacity:.05,duration:1},1)
          .fromTo(this.DOM.ima.querySelector('#Circles'),{opacity:0,},{opacity:1,duration:.8},1.2)


          .to(this.DOM.ima.querySelector('.bg'),{opacity:0,duration:.4},2)
          .set(this.DOM.bggrad,{width:70.69+'vw',immediateRender:false},2)
          .set(this.DOM.bggrad,{width:60.69+'vw',immediateRender:false},'>')
          .fromTo(this.DOM.ima,{'--bgopac':1},{'--bgopac':0,duration:.4,ease:'power2.inOut'},2)
          .to(this.DOM.ima.querySelector('.eye'),{opacity:0,duration:.4,ease:'power2.inOut'},2)
          //Estaba a 360 pero quizás quedaba muy tocho, así que mejor a 270
          .fromTo(this.DOM.ima.querySelector('#Circles'),
          // {width:100+'vw','--str':2+'px','--rot':0+'deg'},
          // {width:270+'vw','--rot':-360+'deg','--str':1+'px',duration:.4},2)
          {width:100+'vw','--str':2+'px','--rot':0+'deg'},
          {width:484+'vh','--rot':-360+'deg','--str':1+'px',duration:.4},2)
          .to(this.DOM.ima.querySelector('.line1'),{'--n':190,'--sep':1.5+'deg',duration:.2},2)
          .set(this.DOM.bggrad,{top:50+'%'},2)
          .fromTo(this.DOM.bggrad,
            {'--gradop':'0',
            '--gradsize':'0%',
          }
          ,{
            '--gradop':'.2',
            '--gradsize':'32%',immediateRender:false,duration:.6},2)
         
          .to(this.DOM.ima.querySelector('.line0'),{opacity:.3,duration:.8,ease:'power2.inOut'},2)
          .to(this.DOM.ima.querySelector('.line1'),{'--scale':1.35,duration:.4,ease:'power2.inOut'},2)
          .to(this.DOM.ima.querySelector('.line2'),{opacity:.3,'--scale':2,duration:.6,ease:'power2.inOut'},2)
          .to(this.DOM.ima.querySelector('.line3'),{opacity:.25,'--scale':2.4,duration:.8,ease:'power2.inOut'},2)
           
          this.im0anim.progress(this.autos.imC)
        }
        else if(state==1){
          if(this.im0anim){
            this.im0anim.progress(this.autos.imC)
          }
        }
        

    
  }

  //👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️
  //👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️
  //👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️👁️



  initEvents(){
    if(this.DOM.tabsc){
      for(let [i,a] of this.DOM.tabsc.entries()){

        a.onclick = () =>{
          for(let u=0;u<this.DOM.tabsc.length;u++){
            if(i == u){
              
              this.DOM.tabs[u].classList.toggle('act')
            }
            else{

              this.DOM.tabs[u].classList.remove('act')
            }
          }
        }

      }
    }

    this.focusFn = ()=>{
        this.killAnim()
        this.createAnim()
     
    }
    this.changeStatBtn = async (direc) => {
      

      if(this.isworking == 1){
        return false
      }
      this.isworking = 1
      
      
      // TITULO SALE
      this.animev.detail.state = -1
      this.animev.detail.params = [1,0,90*direc]
      this.animev.detail.el = this.DOM.statels[this.statpos]
      document.dispatchEvent(this.animev)

        if(direc == 1){

          this.statpos++
          if(this.statpos == this.DOM.statels.length){
            this.statpos=0
          }
        }
        else{
          this.statpos--
          if(this.statpos <= 0){
            this.statpos = this.DOM.statels.length-1
          }
        }

      //TITULO ENTRA


      this.animev.detail.state = 1
      this.animev.detail.params = [1,.6,-90*direc]
      this.animev.detail.el = this.DOM.statels[this.statpos]
      document.dispatchEvent(this.animev)


      //ANIM
      let animobj = {
        x:0,
        y:50,
        o:0,
        rot:0,
        w:0,
        rotneg:0,
        sc:1
      }


      const a = this.DOM.statim.querySelector('.pt0')
      const b = this.DOM.statim.querySelector('.pt1')
      const c = this.DOM.statim.querySelector('.pt2')
      const d = this.DOM.statim.querySelector('.pt3')

      this.animst = gsap.timeline({paused:true,
       
        onUpdate:()=>{

          this.DOM.stat.style.setProperty('--mouse-o', animobj.o)
          this.DOM.stat.style.setProperty('--rotate', animobj.rot)
          this.DOM.stat.style.setProperty('--rotateneg', animobj.rotneg)
          this.DOM.stat.style.setProperty('--w', animobj.w)
  
  
          this.DOM.statbg.style.setProperty('--mouse-o', animobj.o)
          this.DOM.statbg.style.setProperty('--rotate', animobj.rot)
          this.DOM.statbg.style.setProperty('--w', animobj.w)

      }
      ,onComplete:()=>{
        this.isworking = 0
      }
      })

      .to(animobj,{rot:360+'deg',rotneg:-360+'deg',duration:1.6,ease:'power2.out'},0 )
      .to(animobj,{o:1,duration:.2,ease:'none'},0)
      .fromTo(animobj,{w:0+'vh'},{w:42+'vh',duration:.4,ease:'none'},0 )
      .fromTo(animobj,{w:42+'vh'},{w:0+'vh',duration:.4,ease:'none'},1)
      .fromTo(animobj,{o:1},{o:0,duration:.2,ease:'none'},1 )
    .play()

    const rad = 45
      await gsap.timeline()

      .to(c,{'--sc':1,'--o':1,'--b':0+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[0]+this.pointsm,duration:.8,ease:'power1.in'},0)

      .to(d,{'--sc':.8,'--o':1,'--b':0+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[0]+this.pointsm,duration:.9,ease:'power1.in'},0)


      .to(a,{'--sc':.5,'--o':1,'--b':4+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[0]+this.pointsm,duration:1,ease:'power1.in'},0)


      .to(b,{'--sc':.4,'--o':1,'--b':8+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[1]+this.pointsm,duration:1.2,ease:'power1.in'},0)

      



    a.classList.remove('pt0')
    a.classList.add('pt1')
    b.classList.remove('pt1')
    b.classList.add('pt2')
    c.classList.remove('pt2')
    c.classList.add('pt3')
    d.classList.remove('pt3')
    d.classList.add('pt0')



    }

    this.changeStat = async (act=null) => {
      
      if( act != -1  && this.statpos == -1){
        return false
      }

      if( this.statpos == -2){
        return false
      }
      
      let del = 0
      let lastpos = this.statpos
      let animobj = {
        x:0,
        y:50,
        o:0,
        rot:0,
        w:0
      }

      if(this.animst){
        if(this.animst.progress()!= 1){

          del = .21
          this.animst.pause()
          gsap.to(animobj,{o:0,duration:.2,ease:'power2.inOut',onUpdate:()=>{

            this.DOM.stat.style.setProperty('--mouse-o', animobj.o)
          }})
    
        }
      }

      this.animst = gsap.timeline({paused:true,
        onComplete:()=>{

          if(lastpos == -1){
            this.timectr = performance.now() + (this.delay * 2)

          }
          else{
            this.timectr = performance.now() + this.delay

          }
        },
        onUpdate:()=>{

          this.DOM.stat.style.setProperty('--mouse-o', animobj.o)
          this.DOM.stat.style.setProperty('--rotate', animobj.rot)
          this.DOM.stat.style.setProperty('--w', animobj.w)

      }})


      if(this.statpos == -1){
        this.animev.detail.state = 1
        this.animev.detail.params = [.6,.3,-90]
        this.animev.detail.el = this.DOM.statels[0]
        document.dispatchEvent(this.animev)


        this.animst

          .fromTo(animobj,{rot:0+'deg'},{rot:360+'deg',duration:2.2,ease:'power2.out'},.2)
          .fromTo(animobj,{o:0},{o:1,duration:.2,ease:'none'},.6)
          .fromTo(animobj,{w:0+'vw'},{w:29+'vw',duration:.4,ease:'none'},0)
          .fromTo(animobj,{w:29+'vw'},{w:0+'vw',duration:1,ease:'none'},1)
          .fromTo(animobj,{o:1},{o:0,duration:.6,ease:'none'},1)


          this.animst.to(this.DOM.statims[0],{'--sc':1,'--o':1,'--b':0+'px','--r':255+'deg','--rn':-255+'deg','--d':this.statsim[0]+this.pointsm,duration:1.6},0)

          this.animst.to(this.DOM.statims[1],{'--sc':.8,'--o':1,'--b':0+'px','--r':80+'deg','--rn':-80+'deg','--d':this.statsim[0]+this.pointsm,duration:1.8},0)

          this.animst.to(this.DOM.statims[2],{'--sc':.5,'--o':1,'--b':4+'px','--r':190+'deg','--rn':-190+'deg','--d':this.statsim[0]+this.pointsm,duration:2},0)

          this.animst.to(this.DOM.statims[3],{'--sc':.4,'--o':1,'--b':8+'px','--r':340+'deg','--rn':-340+'deg','--d':this.statsim[1]+this.pointsm,duration:2.2},0)

    

        this.animst.play()

      


        this.statpos = 0
      
      }
      else{

        this.timectr = -1

        this.animev.detail.state = -1
        this.animev.detail.params = [.6,0,90]
        this.animev.detail.el = this.DOM.statels[this.statpos]
        document.dispatchEvent(this.animev)

        this.statpos++
        if(this.statpos == this.DOM.statels.length){
          this.statpos = 0
        }
        
        this.animev.detail.state = 1
        this.animev.detail.params = [1,.45,-90]
        this.animev.detail.el = this.DOM.statels[this.statpos]
        document.dispatchEvent(this.animev)

        const a = this.DOM.statim.querySelector('.pt0')
        const b = this.DOM.statim.querySelector('.pt1')
        const c = this.DOM.statim.querySelector('.pt2')
        const d = this.DOM.statim.querySelector('.pt3')

        const rad = 45

        this.animst

          .fromTo(animobj,{rot:0+'deg'},{rot:360+'deg',duration:4.2,ease:'power2.out'},.2 + del)
          .fromTo(animobj,{o:0},{o:1,duration:.2,ease:'none'},.6+del)
          .fromTo(animobj,{w:0+'vw'},{w:29+'vw',duration:.4,ease:'none'},0 +del)
          .fromTo(animobj,{w:29+'vw'},{w:0+'vw',duration:1,ease:'none'},3+del)
          .fromTo(animobj,{o:1},{o:0,duration:.6,ease:'none'},2.6 + del)
        .play()


        gsap.timeline()

          .to(c,{'--sc':1,'--o':1,'--b':0+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[0]+this.pointsm,duration:.8},0)

          .to(d,{'--sc':.8,'--o':1,'--b':0+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[0]+this.pointsm,duration:.9},0)


          .to(a,{'--sc':.5,'--o':1,'--b':4+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[0]+this.pointsm,duration:1},0)


          .to(b,{'--sc':.4,'--o':1,'--b':8+'px','--r':'+='+rad+'deg','--rn':'-='+rad+'deg','--d':this.statsim[1]+this.pointsm,duration:1.2},0)

          



        a.classList.remove('pt0')
        a.classList.add('pt1')
        b.classList.remove('pt1')
        b.classList.add('pt2')
        c.classList.remove('pt2')
        c.classList.add('pt3')
        d.classList.remove('pt3')
        d.classList.add('pt0')

      }


      



    }


  }
  removeEvents(){
   

  }
  //🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟
  //🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟
  //🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟
  //🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟🌟
  // https://codepen.io/cruip/pen/MWZgzzJ
  cxParams(i){
    // const x = Math.floor(Math.random() * this.w)
    // const y = Math.floor(Math.random() * this.h)



    let x = this.w * .5
    x = Math.floor(x)

    let sumx = Math.floor(Math.random() * (this.w * .4) )
    sumx = Math.floor(sumx)

    let zoomx = 0
    let zoomy = 0


    let y = 0
    y = this.h * .5
    y = Math.floor(y)

    
    // let sumy = Math.floor(Math.random() * (this.w * .3) ) + (this.w * .1)
    let sumy = Math.floor(Math.random() * (this.h * .4) ) + (this.w * .32)


    //Math.floor(Math.random() *  (this.w * .1 ) ) + (this.w * .05)
    if(i < 6){
      // console.log('o')

      y -=sumy
      y +=sumx

      zoomy = (sumy) * .2

      // y = Math.floor(Math.random() * (this.h)) + (this.w * .25)
    }
    else {
      y +=sumy
      y -=sumx

      zoomy = (sumy ) * -.2
      // y = Math.floor(Math.random() * (this.h - (this.w * .25)))
      // console.log('u'+y)
    }
   
    

    if(i % 2 == 0){

      // x = Math.floor(Math.random() * this.w * .9 ) + this.w * .8
      // x = Math.floor(Math.random() * (this.w * -.5) ) + this.w * .1
      x += sumx

      zoomx = (sumx ) * -.6
      
    }
    else{
      
      x -= sumx

      zoomx = (sumx ) * .6

      

    }

    
    if(y < this.h * .5){
      // zoomy = (y + (this.h * -.5)) * -.2
    }
    else{
      // zoomy = (y + (this.h * -.5)) * .2
    }
  

    // console.log((x + (this.w * -.5))+' '+zoomx)

    // console.log(zoomy+y)
    // console.log(zoomx)

    const translateX = 0
    const translateY = 0
    const size = Math.floor(Math.random() * 5) + 1
    const alpha = 0
    const targetAlpha = parseFloat((Math.random() * 0.1 + 0.2).toFixed(1))

    const dx = (Math.random() - 0.5) * 0.2
    const dy = (Math.random() - 0.5) * 0.2
    const magnetism = 0.1 + Math.random() * 4



    const arr = ['01','10','00','11','000','101','100','001']

    const val = arr[Math.floor(Math.random() * arr.length)]

    return { x, y, translateX, translateY, size, alpha, targetAlpha, dx, dy, magnetism,zoomx,zoomy,val }


  }

  drawCircle(circle,ops,update = false){
    const { x, y, translateX, translateY, size, alpha,zoomx,zoomy,val } = circle



    if(ops.stars > 0){

      this.ctx.translate(translateX, translateY)
      this.ctx.beginPath()

      // console.log(zoomx*ops.zoom)
      this.ctx.arc(x-(zoomx*ops.zoom), y-(zoomy*ops.zoom), size*( ops.stars ), 0, 2 * Math.PI)
      this.ctx.fillStyle = `rgba(255, 255, 255, ${alpha * ( ops.stars )})`
      this.ctx.fill()
      this.ctx.setTransform(this.dpr, 0, 0, this.dpr, 0, 0)

    }
    if(ops.nums > 0){
      this.ctx.beginPath()
      this.ctx.fillStyle = `rgba(255, 255, 255, ${alpha * ( ops.nums * 2 )})`
      this.ctx.font = (8+size)*( ops.nums )+'px Darker'
      this.ctx.fillText(val, x-5, y+5)
      this.ctx.setTransform(this.dpr, 0, 0, this.dpr, 0, 0)

      
    }

    if (!update) {
      this.circles.push(circle)
    }
  }

  drawCircles(){
    this.ctx.clearRect(0, 0, this.w, this.h)

    const particleCount = 13
    
    for (let i = 0; i < particleCount; i++) {
      const circle = this.cxParams(i)
      this.drawCircle(circle,0)
    }


  }

  remapValue(value, start1, end1, start2, end2) {
    const remapped = (value - start1) * (end2 - start2) / (end1 - start1) + start2;
    return remapped > 0 ? remapped : 0;
  }


  animateCircles(ops) {

    this.ctx.clearRect(0, 0, this.w, this.h)
    this.circles.forEach((circle, i) => {
      const edge = [
        circle.x + circle.translateX - circle.size, // distance from left edge
        this.w - circle.x - circle.translateX - circle.size, // distance from right edge
        circle.y + circle.translateY - circle.size, // distance from top edge
        this.h - circle.y - circle.translateY - circle.size, // distance from bottom edge
      ]
      const closestEdge = edge.reduce((a, b) => Math.min(a, b))
      const remapClosestEdge = this.remapValue(closestEdge, 0, 20, 0, 1).toFixed(2)
      if (remapClosestEdge > 1) {
        circle.alpha += 0.02
        if (circle.alpha > circle.targetAlpha) circle.alpha = circle.targetAlpha
      } 
      else {
        circle.alpha = circle.targetAlpha * remapClosestEdge
      }

      circle.x += circle.dx * .3
      circle.y += circle.dy * .3
      circle.translateX += ((this.mouse.x / (50 / circle.magnetism)) - circle.translateX) / 50
      circle.translateY += ((this.mouse.y / (50 / circle.magnetism)) - circle.translateY) / 50
      
      if (circle.x < -circle.size || circle.x > this.w + circle.size || circle.y < -circle.size || circle.y > this.h + circle.size) {
        this.circles.splice(i, 1)
        const circle = this.cxParams(i)
        this.drawCircle(circle,ops)
        this.drawCircle({ ...circle, x: circle.x, y: circle.y, translateX: circle.translateX, translateY: circle.translateY, alpha: circle.alpha },ops, true)
        
      }else {
        this.drawCircle({ ...circle, x: circle.x, y: circle.y, translateX: circle.translateX, translateY: circle.translateY, alpha: circle.alpha }, ops, true)
      }


    })

  }


  resizeCanvas() {
    // this.circles.length = 0
    this.DOM.canvas.width = this.w * this.dpr
    this.DOM.canvas.height = this.h * this.dpr
    this.DOM.canvas.style.width = this.w+'px'
    this.DOM.canvas.style.height = this.h+'px'
    this.ctx.scale(this.dpr, this.dpr)


  }
  
  //❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥
  //❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥
  //❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥❤️‍🔥




  update(vel,time,y){
    this.animstick.current = window.scrollY   - this.animstick.start
    this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(5)
    this.anim.progress(this.animstick.prog)
    
    // if(this.timectr - time >= 0){
    //   this.changeStat(this.autos.stat)
    // }


    if(this.autos.canvas != 0){
      this.animateCircles(this.autos)
    }


  }

  onResize(pos){
    this.w = window.innerWidth
    this.h = window.innerHeight

    this.animstick.start =  (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    this.animstick.limit = this.DOM.el.clientHeight
    


    // if(this.anim){
    //   this.anim.kill()
    //   this.create()
    //   this.update(window.scrollY)
    // }

    this.calcIm(0)

    // const stp = this.statpos
    // this.statpos = -2
    // setTimeout(()=>{
    //   this.statpos = stp
    // },90)
    

    //Points
    this.calcPoint(null,0)
    
    
    
    // Stats
    // this.calcPoint(null,0)


    // for(let [i,a] of this.DOM.statels.entries()){
    //   this.animev.detail.state = 0
    //   this.animev.detail.el = a
    //   document.dispatchEvent(this.animev)
      
    // }

    if(this.DOM.canvas ){
      this.resizeCanvas()
    }

  }
}
