
import EventEmitter from 'events'
import Field from '/atoms🧿🧿🧿/Field'
import Chk from '/atoms🧿🧿🧿/Chk'
import Selc from '/atoms🧿🧿🧿/Selc'
import { load } from 'recaptcha-v3'

async function asyncFunction() {
  //CYBERC 6Le1GgYpAAAAADMvEWNhcYqY5jKIAEOL5kbd5vIt
  //PRODUCCION 6LdDwMomAAAAAMf4XnsAeBYhuaecgTeNGvoGa6To
  const recaptcha = await load('6LdDwMomAAAAAMf4XnsAeBYhuaecgTeNGvoGa6To',{
    size:'invisible',
    autoHideBadge: true
})
  const token = await recaptcha.execute()

  return token
}

export default class{
  constructor (el,url) {
    
    this.DOM = {
      el:el,
      form:el,
      send:el.querySelector('.btnSend'),
    }

    if(this.DOM.el.querySelectorAll('.Afield')){
      this.fields = []
      for(let b of this.DOM.el.querySelectorAll('.Afield')){
        this.fields.push(new Field(b))
      }
    }
    if(this.DOM.el.querySelectorAll('.Aselect')){
      for(let b of this.DOM.el.querySelectorAll('.Aselect')){
        this.fields.push(new Selc(b))
      }
    }
    if(this.DOM.el.querySelectorAll('.Achk')){
      for(let b of this.DOM.el.querySelectorAll('.Achk')){
        if(b.classList.contains('.Achk-not')){
          continue
        }
        this.fields.push(new Chk(b))
      }
    }

    this.active = 0
    this.base = url
    this.create()
    asyncFunction()
  }

  async create(){


  }
  async sendMail(){
    for(let a of this.fields){
      a.check()
    }

    if(this.DOM.form.querySelector('.err')){
      return false
    }
    let info = []
    let formData = new FormData()

    for(let a of this.fields){
      if(!a.DOM.el.classList.contains('chk')){
        let ob = {label:a.DOM.npt.name, value:a.DOM.npt.value}
        info.push(ob)
        
      }
    }

    const captcha = await asyncFunction()
    let ob = {label:'captcha', value:captcha}
    info.push(ob)


    formData.set("form", JSON.stringify(info))
    //console.log(formData)
    //console.log(info)
    //console.log(JSON.stringify(info))

    await fetch(this.base+'/wp-json/wp/v2/sendform',{
        
      method: 'post',
      body: formData
      // contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
      // processData: false, // NEEDED, DON'T OMIT THIS
    }).then((data)=>{
      // this.closeFn()
      this.DOM.send.classList.add('dis')
    })
  }
  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  async doneFn(){

  }
  initEvents(){
    this.DOM.form.onsubmit = async (ev) =>{
      //Mandar form
      ev.preventDefault()
      this.sendMail()
    }

    
  }
  removeEvents(){
   

  }
  
  onResize(){
  }
}
