
import './index.scss'

export default class {
  constructor (obj,device,touch) {
 
    obj.el.parentNode.setAttribute('id','stck'+obj.pos)

    if(obj.el.dataset.prior){
      this.prior = parseInt(obj.el.dataset.prior)
    }

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      parent:document.querySelector('#stck'+obj.pos),
    }


    this.active = 0
    this.isupdate = 1
    
    this.h = window.innerHeight
    this.create()
  }
  
  create(){



    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      total:0,
    }

    let grad = this.DOM.parent.querySelector('.stck_ball_grad')
    let bg = this.DOM.parent.querySelector('.stck_ball_bg')
    let claim = this.DOM.parent.querySelector('.stck_ball_claim')
    let els = this.DOM.parent.querySelectorAll('.el')
    let cards = this.DOM.parent.querySelectorAll('.card')
    let claiml = this.DOM.parent.querySelector('.stck_ball_claim .tlbl')
    let claimt = this.DOM.parent.querySelector('.stck_ball_claim .tt3')

    cards[0].classList.add('act')
    //cards son 300vh - 3s
    // el resto son 200vh - 2s
    // Le metemos un segundo más para formar el fondo para el siguiente, 
    // cambiamos el animstick.limit y se toca altura con un +20 y quitamos el margin que metí
    //TOTAL 300vh + 220vh
    const endlines = 3

    this.anim = gsap.timeline({paused:true})
    .fromTo(els[0],{
      opacity:1,
    },
    {
      opacity:1,
      duration:.75,
      onComplete:()=>{
        
      }
    }
    ,0)
    .fromTo(els[1],{
      opacity:0,
    },
    {
      opacity:1.5,
      duration:1,
      onStart:()=>{
        cards[0].classList.remove('act')
        cards[1].classList.add('act')
      },
      onReverseComplete:()=>{
        cards[1].classList.remove('act')
        cards[0].classList.add('act')

      },
      onComplete:()=>{

      }
    }
    ,1)
    .fromTo(els[2],{
      opacity:0,
    },
    {
      opacity:1.5,
      duration:1,

      onStart:()=>{
        cards[1].classList.remove('act')
        cards[2].classList.add('act')
      },
      onReverseComplete:()=>{
        cards[2].classList.remove('act')
        cards[1].classList.add('act')

      },
      onComplete:()=>{

      }
    }
    ,2)
    .fromTo(bg,
      {
        width:140+'vw',
        height:20+'vw',
        '--x':-50+'%',
        '--y':100+'%',
      },
      {
        width:80+'vh',
        height:80+'vh',
        '--x':-50+'%',
        '--y':-50+'%',
        duration:1}
    ,endlines)
    .fromTo(grad,
      {
        width:50+'vw',
        height:50+'vw',
        '--x':-105+'%',
        '--y':10+'%',
        '--gradop':.2,
        '--gradsize':30+'%',

      },
      {
        width:80+'vh',
        height:80+'vh',
        '--x':-50+'%',
        '--y':5+'%',
        '--gradop':.34,
        '--gradsize':50+'%',
        duration:endlines}
    ,0)
    .fromTo(grad,
      {
        width:80+'vh',
        height:80+'vh',
        '--y':5+'%',
      },
      {
        width:140+'vh',
        height:140+'vh',
        '--y':-50+'%',
        immediateRender:false,
        duration:1}
    ,endlines)
    .set(claim,
      {
        opacity:0}
    ,'>')
    .set(claim,
      {
        immediateRender:false,
        opacity:1}
    ,'>')
    .fromTo(claiml,
      {
        opacity:0,
      },
      {
        opacity:1,
        duration:.5}
    ,endlines+1.5)
    .fromTo(claimt,
      {
        opacity:0,
        '--b':6+'px',
        '--z':-40+'vw'
      },
      {
        opacity:1,
        '--b':0+'px',
        '--z':0+'vw',
        duration:1}
    ,endlines+1)
    
    .fromTo(bg,
      {
        width:80+'vh',
        height:80+'vh',
      },
      {
        width:120+'vh',
        height:120+'vh',
        immediateRender:false,
        duration:1}
    ,endlines+2.2)
    .fromTo(grad,
      {
        width:140+'vh',
        height:140+'vh',

      },
      {
        width:160+'vh',
        height:160+'vh',
        immediateRender:false,
        duration:1}
    ,endlines+2.2)
    //end anim
    .fromTo(bg,
      {
        opacity:1,

      },
      {
        opacity:0,
        immediateRender:false,
        duration:.2}
    ,endlines+3)

    .fromTo(grad,
      {
        opacity:1,

      },
      {
        opacity:0,
        immediateRender:false,
        duration:.2}
    ,endlines+3)
    .fromTo(claiml,
      {
        opacity:1,
      },
      {
        opacity:0,
        immediateRender:false,
        duration:.5}
    ,endlines+2.2)
    .fromTo(claimt,
      {
        opacity:1,
        '--b':0+'px',
        '--z':0+'vw',
      },
      {
        opacity:0,
        '--b':6+'px',
        '--z':40+'vw',
        immediateRender:false,
        duration:1}
    ,endlines+2.2)
  }

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        
        this.stop()
      }

    return vis

  }
  
  start(){
    if(this.active == 1){
      return false
    }
    this.active = 1
  }
  
  stop(){
    if(this.active == 0){
      return false
    }
    
    this.active = 0

   

  }


  initEvents(){
    
  }
  removeEvents(){

  }

  

  update(pos){

    this.animstick.current = pos - this.animstick.start
    //CLAMP
    this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
   
    this.anim.progress(this.animstick.prog)
    // console.log(this.animstick.prog)

  }

  onResize(){

    
    this.w = window.innerWidth
    this.h = window.innerHeight

    this.animstick.start =  (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    this.animstick.limit = (this.DOM.el.clientHeight).toFixed(0)
    
    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    // if(this.anim){
    //   this.anim.kill()
    //   this.create()
    //   this.update(window.scrollY)
    // }


  }
  


  
}
