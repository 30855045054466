

export default class{
  constructor (obj,isauto,touch) {
    
    this.el = obj.el    


    this.pos = obj.pos
    this.isauto = isauto
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      video:obj.el.parentNode.querySelector('video'),
    }


    this.active = 0
    
    


    this.create()
  }
  
  create(){

    
  }
  check(entry,pos){
    let vis = false
    let isPlaying = this.DOM.video.currentTime > 0 && !this.DOM.video.paused && !this.DOM.video.ended 
    && this.DOM.video.readyState > this.DOM.video.HAVE_CURRENT_DATA
      if(entry.isIntersecting==undefined && isPlaying){
        this.DOM.video.pause()
        return false
      }
      else if(entry.isIntersecting==true){
        this.DOM.video.classList.add('Auto')
        if(this.DOM.video.classList.contains('Ldd')){
          this.DOM.video.play()

        }
      }
      else{
        this.DOM.video.classList.remove('Auto')
        if(isPlaying){
          this.DOM.video.pause()
        }
      }
     
      
      

    return false

  }
  
  start(){
    this.active = 1

  }
  
  stop(){

    this.active = 0

  }

  initEvents(){

  }
  removeEvents(){

  }
  update(){
      
      
  }

  onResize(scrollCurrent){
    this.h = window.innerHeight
    this.max =this.el.clientHeight

    this.checkobj = {
      firstin: Math.min(this.h,this.el.clientHeight)*0.92,
      firstout:this.h*0.92,
      secout:this.el.clientHeight*-1,
      limit:this.el.clientHeight

    }
  }
  
}
