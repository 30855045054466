import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'

import Cards from './2Cards'
import Newsletter from './3Newsletter'

class Home extends Page {
	constructor(main) {
		super(main)
	}

	async create(content, main, temp = undefined) {
		super.create(content, main)
		if (temp != undefined) {
			document.querySelector('#content').insertAdjacentHTML('afterbegin', temp)
		} else {
			let data = await this.loadRestApi(this.main.base + '/wp-json/wp/v2/posts/', content.dataset.id, content.dataset.template)
			document.querySelector('#content').insertAdjacentHTML('afterbegin', data.csskfields.main)
		}

		this.el = document.querySelector('main')

		this.DOM = {
			el: this.el,
			hold: this.el.querySelector('.post_cards .cards'),
			cards: this.el.querySelectorAll('.post_cards .cards_el'),
		}
		this.id = content.dataset.id

		if (this.DOM.cards.length !== 3) {
			let qty = 3 - this.DOM.cards.length
			await this.loadCards(1, 0, qty)
		} else {
			this.newio = document.createElement('div')
			this.newio.classList.add('iO-cards')
			this.newio.classList.add('iO')
			this.DOM.hold.insertAdjacentHTML('beforeend', this.newio.outerHTML)
		}

		await this.loadImages()
		await this.loadVideos()

		await this.createComps()
		await this.createIos()

		await this.getReady()
	}

	async loadCards(page = null, type = 0, perpage = null) {
		Promise.all([
			fetch(this.main.base + '/wp-json/wp/v2/getcards?device=' + this.main.device + '&webp=' + this.main.webp + '&perpage=' + perpage, {
				method: 'GET',
			}),
			this.timeout(60),
		]).then(async (response) => {
			const data = await response[0].json()
			// console.log(data.posts)
			this.DOM.hold.insertAdjacentHTML('beforeend', data.html)
			document.dispatchEvent(this.main.events.newlinks)

			this.newio = document.createElement('div')
			this.newio.classList.add('iO-cards')
			this.newio.classList.add('iO')
			this.DOM.hold.insertAdjacentHTML('beforeend', this.newio.outerHTML)

			await this.timeout(450)

			await this.newIos()
			await this.loadImages()
		})
	}

	iOpage(animobj) {
		if (animobj.el.classList.contains('iO-cards')) {
			animobj.class = new Cards(animobj, this.main.device, this.main.touch)
		}
		return animobj
	}

	async createComps() {
		await super.createComps()
		// if (this.DOM.el.querySelector(".post_intro")) {
		//   this.components.intro = new Intro(
		//     this.DOM.el.querySelector(".post_intro"),
		//     this.main.device
		//   );
		// }

    this.components.newsletter = new Newsletter(this.DOM.el.querySelector('.post_newsletter'),this.main.base)
    await this.components.newsletter.create()
	}

	async animIntro(val) {
		if (this.components.intro) {
			this.components.intro.start()
		}

		return val
	}

	async animOut() {}
}

export default Home
