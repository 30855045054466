
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch,animev) {

    this.pos = obj.pos
    this.device = device
    this.touch = touch


    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode,
      statels:obj.el.parentNode.querySelectorAll('.cnt_stat .el'),
      // statctr:obj.el.parentNode.querySelectorAll('.cnt_stat_ctr span'),
    }



    this.animev = animev

    this.active = 0


    this.statpos = -1


    this.w = window.innerWidth
    this.h = window.innerHeight
    

    

    this.create()
  }
   
  async create(){

    this.autos = {
      stat : 0
    }

    for(let [i,a] of this.DOM.statels.entries()){
      if(a.querySelector('.t').innerHTML.length > 4){
        a.querySelector('.t').classList.add('t-s')
      }
      this.animev.detail.state = 0
      this.animev.detail.el = a
      document.dispatchEvent(this.animev)
      
    }


  }


  

  check(entry,pos){
    let vis = false
      if(entry.isIntersecting == true){
        
        vis = true
        this.start(pos)

      }
      else{
       
        this.stop(pos)
      }
    return vis

  }
  
  start(pos=0){
    if(this.active==1){
      return false
    }
    if(this.statpos == -1){
      this.changeStatBtn(1)
    }
    
    this.active = 1


  }
  stop(pos = null){
    // console.log('stop intro'+this.active)

    if(this.active==0){
      return false
    }

    this.active = 0


    // this.killAnim()
    // this.anim.progress(0)
    // console.log('stop intro change'+this.active)

   

  }
  removeEvents(){
  
  }
  


  update(pos,time){
  }


  onResize(pos){
    this.w = window.innerWidth
    this.h = window.innerHeight


   

    
  }









  initEvents(){
    this.changeStat = async (act=null) => {
      
      let del = 0
      let lastpos = this.statpos

      

      gsap.to(this.DOM.parent,{opacity:2,duration:2,
        onStart:()=>{
          this.times[0] = -1
        },
        onComplete:()=>{
          if(lastpos == -1){
            this.times[0] = performance.now()

          }
          else{
            this.times[0] = performance.now()

          }
        },
      })

      if(this.statpos == -1){
        this.times[0] = -1
        this.animev.detail.state = 1
        this.animev.detail.params = [.6,.3,-90]
        this.animev.detail.el = this.DOM.statels[0]
        document.dispatchEvent(this.animev)
        // this.DOM.statctr[0].classList.add('act')


       

        this.statpos = 0
      
      }
      else{
        this.times[0] = -1

        this.animev.detail.state = -1
        this.animev.detail.params = [.6,0,90]
        this.animev.detail.el = this.DOM.statels[this.statpos]
        document.dispatchEvent(this.animev)

        this.DOM.statctr[this.statpos].classList.remove('act')

        this.statpos++
        if(this.statpos == this.DOM.statels.length){
          this.statpos = 0
        }

        this.DOM.statctr[this.statpos].classList.add('act')
        
        this.animev.detail.state = 1
        this.animev.detail.params = [1,.45,-90]
        this.animev.detail.el = this.DOM.statels[this.statpos]
        document.dispatchEvent(this.animev)


      }


      



    }



    this.changeStatBtn = async (direc) => {
      

      
      // TITULO SALE
      if(this.statpos != -1){
        this.animev.detail.state = -1
        this.animev.detail.params = [1,0,90*direc]
        this.animev.detail.el = this.DOM.statels[this.statpos]
        document.dispatchEvent(this.animev)
      }
      else{
        gsap.to(this.DOM.parent,{opacity:1,duration:.6,
         
        })
      }


        if(direc == 1){

          this.statpos++
          if(this.statpos == this.DOM.statels.length){
            this.statpos=0
          }
        }
        else{
          this.statpos--
          if(this.statpos <= 0){
            this.statpos = this.DOM.statels.length-1
          }
        }

      //TITULO ENTRA


      this.animev.detail.state = 1
      this.animev.detail.params = [1,.6,-90*direc]
      this.animev.detail.el = this.DOM.statels[this.statpos]
      document.dispatchEvent(this.animev)






    }
  }
}
