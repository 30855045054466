import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS

import Intro from './0Intro'
// import Blur from './1Blur'
import Eclipse from './2Eclipse'
import Platform from './3Platform'
import Cases from './4Cases'

class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){
      
      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{

      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    if(animobj.el.classList.contains('iO-eclipse')){


      animobj.class = new Eclipse(animobj,this.main.device,this.main.isTouch)
      
    }
    else if(animobj.el.classList.contains('iO-platform')){

      animobj.class = new Platform(animobj,this.main.device,this.main.isTouch)
    }
    else if(animobj.el.classList.contains('iO-cases')){

      animobj.class = new Cases(animobj,this.main.device,this.main.isTouch)
      
    }
    
    return animobj
  }

  
  
  async createComps(){
   
    this.components.intro = new Intro(this.DOM.el.querySelector('.design_intro'),this.main.device)

    await super.createComps()
    
  }


  async animIntro(val){

    this.components.intro.start()
    

    return val
   
  }

  async animOut(){
    await gsap.to(this.el,{opacity:0,duration:.45,ease:'Power2.inOut'})

  }

}




export default Home