'use strict';

import './index.scss'


export default class {
  constructor(el){
    this.DOM = {
      el: el,
    }
    this.DOM.npt =  this.DOM.el.querySelector(".Aselect_npt")
    
    this.initEvents()
  }
  initEvents() {
    
    this.DOM.npt.onchange = (ev)=>{
      if(this.DOM.npt.value != ""){
        this.DOM.npt.classList.add("selected")
      }
    }
  }
  check(){
    
    
    
  }
  clear(){
      
  }
  removeEvents() {
      
  }
}
