

export default class{
  constructor (obj,device,touch) {
    

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      io:obj.el,
      el:obj.el.parentNode
    }


    this.active = 0
    
    


    this.create()
  }
  
  create(){

    this.DOM.el.style.setProperty('--mouse-x', 0+'%')
    this.DOM.el.style.setProperty('--mouse-y', 0+'%')
    this.DOM.el.style.setProperty('--mouse-o', 0)


    let animobj = {
      x:0,
      y:10,
      o:0
    }

    if(this.DOM.io.dataset.st == '1'){

      this.anim = gsap.timeline({
        paused:true,repeatDelay:2,repeat:-1,
        onUpdate:()=>{

          this.DOM.el.style.setProperty('--mouse-x', animobj.x+'%')
          this.DOM.el.style.setProperty('--mouse-y', animobj.y+'%')
          this.DOM.el.style.setProperty('--mouse-o', animobj.o)
          
          
        }
      })
      animobj = {
        x:0,
        y:50,
        o:0
      }
  
      this.anim.fromTo(animobj,{x:0},{x:100,duration:1.2,ease:'power2.in'},.3)
      this.anim.fromTo(animobj,{x:100},{x:0,duration:1.2,ease:'power2.out'},2.2)
  
  
      this.anim.fromTo(animobj,{o:0},{o:1,duration:.2,ease:'power2.inOut'},0)
      this.anim.fromTo(animobj,{o:1},{o:0,duration:.4,ease:'power2.inOut'},3)

      this.anim.fromTo(animobj,{y:50},{y:0,duration:.4,ease:'none'},0)
      this.anim.fromTo(animobj,{y:0},{y:100,duration:1,ease:'none'},1.4)
      this.anim.fromTo(animobj,{y:100},{y:40,duration:.4,ease:'none'},3)
      
    }
    else{


    this.anim = gsap.timeline({
      paused:true,repeatDelay:2,repeat:-1,
      onUpdate:()=>{

        this.DOM.el.style.setProperty('--mouse-x', animobj.x+'%')
        this.DOM.el.style.setProperty('--mouse-y', animobj.y+'%')
        this.DOM.el.style.setProperty('--mouse-o', animobj.o)
        
        
      }
    })
    

    animobj = {
      x:0,
      y:10,
      o:0
    }

    this.anim.fromTo(animobj,{x:0},{x:100,duration:.8,ease:'power2.in'},0)
    this.anim.fromTo(animobj,{x:100},{x:0,duration:.8,ease:'power2.out'},1)


    this.anim.fromTo(animobj,{o:0},{o:1,duration:.2,ease:'power2.inOut'},0)
    this.anim.fromTo(animobj,{o:1},{o:0,duration:.2,ease:'power2.inOut'},1.6)
    this.anim.fromTo(animobj,{y:50},{y:0,duration:.1,ease:'none'},0)
    this.anim.fromTo(animobj,{y:0},{y:100,duration:.2,ease:'none'},.8)
    this.anim.fromTo(animobj,{y:100},{y:0,duration:.1,ease:'none'},1.75)




   }
    


   this.animout = gsap.to(animobj,{o:0,duration:.2,
    onUpdate:()=>{
      this.DOM.el.style.setProperty('--mouse-o', animobj.o)

   }
    })

  }
  check(entry,pos){
    let vis = false
      
    vis = entry.isIntersecting
    
    if(vis == 1){
      this.start()

    }
    else if(vis == 0){
      this.stop()
    }
    return vis

  }
  
  start(){
    if(this.active == 1){
      return false
    }
    this.DOM.el.classList.add('inview')
    this.anim.restart()
    this.active = 1

  }
  
  stop(){
    if(this.active == 0){
      return false
    }
    this.anim.pause()
    this.animout.restart()
    this.active = 0

  }

  initEvents(){

  }
  removeEvents(){

  }
  update(){
      
      
  }

  onResize(scrollCurrent){
    // this.h = window.innerHeight
    // this.max =this.el.clientHeight

    // this.checkobj = {
    //   firstin: Math.min(this.h,this.DOM.io.clientHeight)*0.92,
    //   firstout:this.h*0.92,
    //   secout:this.DOM.io.clientHeight*-1,
    //   limit:this.DOM.io.clientHeight

    // }
  }
  
}
