
import './index.scss'

export default class {
  constructor (obj,device,touch) {
 

    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
    }


    
    this.create()
  }
  
  create(){


    this.sliderpos = -1

    this.DOM.slides = this.DOM.el.parentNode.querySelectorAll('.cnt_slides_el')
    this.lgt = this.DOM.slides.length
  

    this.DOM.l = this.DOM.el.parentNode.querySelector('#btL')
    this.DOM.r = this.DOM.el.parentNode.querySelector('#btR')

  }

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        
        this.stop()
      }

    return vis

  }
  
  start(){
    if(this.sliderpos == -1){ 
      this.DOM.slides[0].classList.add('act')
      this.sliderpos = 0
    }


  }
  
  stop(){
    
    this.active = 0

   

  }

  initEvents(){
    this.DOM.l.onclick = () => {
 
     this.DOM.slides[this.sliderpos].classList.remove('act')
     this.sliderpos--
     if(this.sliderpos < 0 ){
       this.sliderpos = this.lgt-1
     }
 
     this.DOM.slides[this.sliderpos].classList.add('act')
 
    }
    this.DOM.r.onclick = () => {
     this.DOM.slides[this.sliderpos].classList.remove('act')
     this.sliderpos++
     if(this.sliderpos == this.lgt ){
       this.sliderpos = 0
     }
 
     this.DOM.slides[this.sliderpos].classList.add('act')
 
    }
  }
  removeEvents(){

  }

  

  update(){
  }
 
  onResize(){

    

  }
  


  
}
