
export default class {
  constructor (el) {

    this.el = el    
   

    this.DOM = {
      el:el,
      elsclick:el.querySelectorAll('.el_click')
    }


    this.pos = -1
    this.create()
  }
  
  create(){
  
  }


  

  initEvents(){
   for(let [i,a] of this.DOM.elsclick.entries()){
    a.onclick = () =>{
      if(this.pos != -1){
        this.DOM.elsclick[this.pos].parentNode.classList.remove('act')
      }
      if(i == this.pos){
        this.pos = -1
        return false
      }
      this.pos = i
      this.DOM.elsclick[this.pos].parentNode.classList.add('act')
    }
   }

  }
  removeEvents(){

  }
  
  
}