// import Selc from '/atoms🧿🧿🧿/Selc'
import Field from '/atoms🧿🧿🧿/Field'
// import Chk from '/atoms🧿🧿🧿/Chk'

export async function create(content, main, temp = undefined) {
	this.ios = []
	this.iosupdaters = []
	this.updaters = []

	this.components = {}
	this.main = main

	if (this.main.isTouch) {
		this.scroll = {
			target: 0,
			current: 0,
			limit: 0,
			last: 0,
			down: 1,
		}
	} else {
		this.scroll = {
			target: 0,
			last: 0,
			limit: 0,
			down: 1,
		}
	}
}

//* Función crear componentes
export async function createComps() {
	if (this.DOM.el.querySelectorAll('.detX')) {
		for (let a of this.DOM.el.querySelectorAll('.detX')) {
			a.querySelector('.detX_click').onclick = async (e) => {
				if (a.classList.contains('act')) {
					e.preventDefault()
					a.classList.remove('act')
					// a.querySelector('.detX_hold').style.height = 0+'px'
					await this.timeout(450)
					a.removeAttribute('open')
				} else {
					await this.timeout(12)
					a.classList.add('act')
					// a.querySelector('.detX_hold').style.height = a.querySelector('.detX_box').clientHeight+'px'
				}
			}
		}
	}

	if (this.DOM.el.querySelectorAll('.Ox')) {
		for (let a of this.DOM.el.querySelectorAll('.Ox')) {
			a.parentNode.querySelectorAll('.bullets_el')[0].classList.add('act')
			a.onscroll = (ev) => {
				// console.log(ev)
				// console.log(a.scrollLeft)
				// console.log(a.scrollWidth+' '+a.clientWidth)
				// console.log(a.scrollWidth)
				let pos = ((a.scrollLeft + 0.2 * a.clientWidth) * parseInt(a.dataset.size)) / (a.scrollWidth - a.clientWidth)
				pos = Math.floor(pos)

				console.log(pos + ' ' + parseInt(a.dataset.act))

				if (parseInt(a.dataset.act) == pos) {
					return false
				}

				a.dataset.act = pos

				console.log(pos)

				a.parentNode.querySelector('.bullets_el.act').classList.remove('act')
				a.parentNode.querySelectorAll('.bullets_el')[pos].classList.add('act')
			}
		}
	}
	// BTNS

	// //FIELDS

	// if(document.querySelectorAll('.Aselc')){
	//   this.components.fields = []
	//   for(let b of document.querySelectorAll('.Aselc')){
	//     this.components.fields.push(new Selc(b))
	//   }
	// }

	if (document.querySelectorAll('.Afield')) {
		this.components.fields = []
		for (let b of document.querySelectorAll('.Afield')) {
			this.components.fields.push(new Field(b))
		}
	}

	// //TABS
	// if(document.querySelector('.Tab')){
	//   this.components.tabs = []
	//   for(let [index,a] of document.querySelectorAll('.Tab').entries()){
	//     this.components.tabs.push(new Tab(a))
	//     this.components.tabs[index].on('resize',()=>{
	//       this.resizeLimit()
	//     })
	//   }

	// }

	// if(document.querySelector('.Amasonry')){
	//   this.components.masonry = []
	//   for(let [index,a] of document.querySelectorAll('.Amasonry').entries()){
	//     this.components.masonry.push(new Masonry(a))

	//   }

	// }

	//COMPS
	// if(this.DOM.el.querySelector('.Csuscribe')){
	//   this.components.suscribe = new Suscribe(this.main,this.DOM.el.querySelector('.Csuscribe'))

	// }
}

export function cleanP() {
	if (this.DOM.el.querySelector('p:empty')) {
		for (let p of this.DOM.el.querySelectorAll('p:empty')) {
			p.remove()
		}
	}
}

export function cleanWysi() {
	for (let a of this.DOM.el.querySelectorAll('.wysi *')) {
		a.removeAttribute('style')
		a.removeAttribute('width')
		
		
	}
	for (let a of this.DOM.el.querySelectorAll('.wysi img')) {
		// a.removeAttribute("loading");
		// let src = a.src
		// a.dataset.src = src
		// a.removeAttribute('src')
		// a.removeAttribute("width");
		// a.removeAttribute("height");

		let parent = a.parentNode
		// console.log(parent.tagName)
		if (parent.tagName == 'P') {
			// parent.parent.replaceChild(a,parent)

			parent.parentNode.insertBefore(a, parent)
			parent.remove()
		}
	}
	for (let a of this.DOM.el.querySelectorAll('.wysi table')) {
		if (a.parentNode.tagName == 'DIV') {
			a.parentNode.style.overflowX = 'scroll'
			a.parentNode.style.width = '100%'
		}
	}
}
