import Page from "/js🧠🧠🧠/page👁️/pagemain.js";

//COMPS
import Intro from "./Intro";
import Mainform from "./Main";

class Home extends Page {
  constructor(main, footer) {
    super(main, footer);
  }

  async create(content, main, temp = undefined) {
    super.create(content, main);
    if (temp != undefined) {
      document.querySelector("#content").insertAdjacentHTML("afterbegin", temp);
    } else {
      let data = await this.loadRestApi(
        this.main.base + "/wp-json/wp/v2/pages/",
        content.dataset.id,
        content.dataset.template
      );
      document
        .querySelector("#content")
        .insertAdjacentHTML("afterbegin", data.csskfields.main);
    }
    this.el = document.querySelector("main");

    this.DOM = {
      el: this.el,
    };

    await this.loadImages();
    await this.loadVideos();

    await this.createComps();
    await this.createIos();

    await this.getReady();
  }
  iOpage(animobj) {
    return animobj;
  }

  async createComps() {
    await super.createComps();

    if (this.DOM.el.querySelector(".contact_intro")) {
      this.components.intro = new Intro(
        this.DOM.el.querySelector(".contact_intro"),
        this.device
      );
    }
    if (this.DOM.el.querySelector(".contact_intro form")) {
      this.components.form = new Mainform(
        this.DOM.el.querySelector(".contact_intro form"),
        this.main.base
      );
    }
  }

  async animIntro(val) {
    await this.components.intro.start();
    return val;
  }

  async animOut() {
    await gsap.to(this.el, {
      opacity: 0,
      duration: 0.45,
      ease: "Power2.inOut",
    });
  }
}

export default Home;
