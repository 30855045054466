
export function lerp (t,s,r) {
  // return gsap.utils.interpolate(p1, p2, t)
  return t * (1 - r) + s * r
}

export function clamp (min, max, number) {
  return gsap.utils.clamp(min, max, number)
}

export function random (min, max) {
  return gsap.utils.random(min, max)
}

export function scroll(x) {
  return Math.min(1, 1.001 - Math.pow(2, -10 * x));
}

export function map (valueToMap, inMin, inMax, outMin, outMax) {
  return gsap.utils.mapRange(inMin, inMax, outMin, outMax, valueToMap)
}

export function timeout(ms){
  return new Promise(resolve => setTimeout(resolve, ms))
}


export function checkVisibleEl(elm,h) {
  var rect = elm.getBoundingClientRect()
  return !(rect.bottom < 0 || rect.top - h >= 0)
}

// export function checkVisible(elm,h) {
//   var rect = elm.getBoundingClientRect()
//   return !(rect.bottom < 0 || rect.top - h >= 0)
// }

export function checkVisible(elm,h) {
  return !(elm.boundingClientRect.bottom < 0 || elm.boundingClientRect.top - h >= 0)
}

export function checkVisibleXY(elm,w,h) {
  let vis = true
  // console.log(elm.target.dataset.json==)
  vis = !(elm.boundingClientRect.bottom < 0 || elm.boundingClientRect.top - h >= 0)
  if(vis == true){
    vis = !(elm.boundingClientRect.right < 0 || elm.boundingClientRect.left - w >= 0)
  }
  
  return vis
}


export function checkVisibleX(elm,w) {
  let vis = [false,false]
  vis[0] = !(elm.boundingClientRect.right < 0 || elm.boundingClientRect.left - w >= 0)
  vis[1] = elm.boundingClientRect.left
  // console.log(vis)
  
  return vis
}


export function checkPos(pos,check) {
  let result = [0,0]
    if(check.intersect==true){
      result[0] = 1
      if(pos > 100){
        result[1] = 0
      }
      else{
        result[1] = 1
      }
    }
    else{
      result[0] = 0
        if(pos > 0 && pos < check.firstout){
          result[1] = 1
        }
        if(pos < -100){
          result[1] = 1
          
        }

    }

    




  return result

  
}

export  function randomArr(min,max,length){
  let ranNum = 0
  let ranList = []
  do{
    ranNum = Math.floor(Math.random() * max + min)
    if(ranNum == ranList[ranList.length - 1]){
      do{
        ranNum = Math.floor(Math.random() * max + min)
      }while(ranNum == ranList[ranList.length - 1])
    }
    ranList.push(ranNum)
  }while(ranList.length !== length) 
  return ranList
}

// R.iLerp = (t,r,s)=>R.Clamp((s - t) / (r - t), 0, 1),
// R.Lerp = (t,r,s)=>t * (1 - s) + r * s,
// R.LerpD = (t,r,s)=>t + (r - t) * (s / (16.6666666666667 / RafFr)),
// R.LerpM = (t,r,s)=>t + (r - t) * s,
// R.Remap = (t,r,s,e,i)=>R.Lerp(s, e, R.iLerp(t, r, i)),