

export default class {
  constructor (el,device,url) {


    this.DOM = {
      el:el,
    }

    this.active = 0
    this.create()
  }

  async create(){

    this.anim = gsap.timeline({paused:true})
    
    .fromTo(this.DOM.el.parentNode,{opacity:0},{opacity:1,duration:.6,ease:'power2.inOut'},0)
    .fromTo(this.DOM.el.querySelector('.cnt_t'),{y:-2.2+'rem'},{y:0+'rem',duration:1,ease:'power2.inOut'},0)
    .fromTo(this.DOM.el.querySelector('.cnt_x'),{opacity:0,y:2.2+'rem'},{opacity:1,y:0+'rem',duration:1,ease:'power2.inOut'},.4)
    
    
  
  }
  
  async start(){
    this.anim.play()
  }


  

  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
