

export default class {
  constructor (obj,device) {


    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode
    }
    this.device = device
    this.active = 0
    this.isupdate = 2

    this.posS = -1
    this.timer = -1
      
    this.create()
    
  }

  async create(){
    const cover = this.DOM.parent.querySelector('.cnt_sld')
    const els = this.DOM.parent.querySelectorAll('.el')



    let row = ''
    // let cont = 0
    for(let [i,a] of els.entries()){
      // cont++
      if((i) % 4 == 0 ){

        row = document.createElement('div')
        cover.appendChild(row)
        row.classList.add('cnt_sld_r')
        // cont=0
      }

      row.appendChild(a)
      // a.classList.add('el-'+cont)

    }
    this.DOM.rows = this.DOM.parent.querySelectorAll('.cnt_sld_r')
    // for(let b of this.DOM.parent.querySelectorAll('.cnt_sld_r')){
    //   b.classList.add('cnt_sld_r-'+b.querySelectorAll('.el').length)
    // }
  
  }
  
  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }


  async start(){

    if(this.active==1){
      return false
    }
    this.active = 1
    if(this.timer == -1){
      this.cSlide()
    }
    else{
      this.timer = performance.now() - 1800

    }
  }


  stop(){

    if(this.active==0){
      return false
    }

    this.active = 0


  }

  initEvents(){

  }
  async cSlide(){
    let del = 0
    
    
    
    if(this.posS == -1 ){
      del = .6
    }
    else{

      this.DOM.rows[this.posS].classList.remove('act')
    }


    await window.timeout(del)
    
    if(this.posS == -1 ){
      this.DOM.el.classList.add('big')
      this.timer = performance.now()
    }
    this.posS++
    if(this.posS == this.DOM.rows.length){
      this.posS = 0
    }
    this.DOM.rows[this.posS].classList.add('act')
  }
  removeEvents(){
    
  }

  update(pos,time){
    if(this.timer == -1){
      return false
    }

      if(time - this.timer >= 3100){
        this.cSlide()
        this.timer = time
      }
  }


  onResize(){
  }
}
