import Page from "/js🧠🧠🧠/page👁️/pagemain.js";

//COMPS

class Home extends Page {
  constructor(main) {
    super(main);

    this.page = 1;
    this.perpage = 6;
    this.max = 1;

    this.cat;
  }

  async create(content, main, temp = undefined) {
    super.create(content, main);
    if (temp != undefined) {
      document.querySelector("#content").insertAdjacentHTML("afterbegin", temp);
    } else {
      let data = await this.loadRestApi(this.main.base + "/wp-json/wp/v2/pages/", content.dataset.id, content.dataset.template);

      document.querySelector("#content").insertAdjacentHTML("afterbegin", data.csskfields.main);
    }

    this.el = document.querySelector("main");

    this.DOM = {
      el: this.el,
      hold: this.el.querySelector(".blog_main .cnt_h"),
      paghold: this.el.querySelector(".blog_main .pages_hold"),
      pagination: this.el.querySelector(".blog_main .pages"),
      cats: this.el.querySelectorAll(".cnt_tp_cats .Acat"),
      title: this.el.querySelector(".blog_intro .cnt .tt2"),
      image: this.el.querySelector(".blog_intro .cnt_image"),
      prev: this.el.querySelector(".toLeft"),
      next: this.el.querySelector(".toRight"),
      search: this.el.querySelector(".blog_main .search"),
      searchNpt: this.el.querySelector(".blog_main .search input"),
    };

    await this.loadImages();
    await this.loadVideos();

    await this.createComps();
    await this.createIos();

    if (this.el.querySelector('.cnt_tp_cats .Acat[data-id="' + content.dataset.cat + '"]')) {
      this.el.querySelector('.cnt_tp_cats .Acat[data-id="' + content.dataset.cat + '"]').classList.add("act");
    }

    await this.loadPosts(content.dataset.cat, 1, 2);

    await this.getReady();
    this.initEvents();
  }
  iOpage(animobj) {
    return animobj;
  }

  async loadPosts(cat = null, page = null, type = 0, search = null) {
    //Type 0 : Cambio por paginación
    //Type 1 : Cambio de categoría
    //Type 2 : Inicial
    let time = 1;
    if (type < 2) {
      time = 450;
      this.DOM.hold.classList.add("load");
      this.DOM.pagination.classList.add("load");
    }

    if (cat == null) {
      this.cat = cat;
    } else {
      this.cat = cat;
    }

    if (search == null) {
      this.search = "";
    } else {
      this.search = search;
    }

    if (page == null) {
      this.page = 1;
    } else {
      this.page = parseInt(page);
    }

    Promise.all([
      fetch(this.main.base + "/wp-json/wp/v2/getposts?device=" + this.main.device + "&webp=" + this.main.webp + "&cat=" + this.cat + "&page=" + this.page + "&perpage=" + this.perpage + "&search=" + this.search, {
        method: "GET",
      }),
      this.timeout(time),
    ]).then(async (response) => {
      // LIMPIAR TODOS LOS POSTS ANTERIORES
      for (let a of this.DOM.el.querySelectorAll(".blog_main .cnt_h > *")) {
        a.remove();
      }

      const data = await response[0].json();
      // console.log(data)
      this.DOM.hold.insertAdjacentHTML("beforeend", data.html);
      document.dispatchEvent(this.main.events.newlinks);
      this.max = parseInt(data.maxpages);

      if (this.max <= 1) {
        this.DOM.pagination.classList.add("hide");
      } else {
        this.DOM.pagination.classList.remove("hide");
      }

      //PAGINACIÓN
      this.createPags();

      await this.timeout(450);
      this.DOM.hold.classList.remove("load");
      this.DOM.pagination.classList.remove("load");

      this.newIos();
    });
  }

  async createPags() {
    if (this.DOM.pages) {
      this.DOM.paghold.classList.add("load");
      await this.timeout(450);
      for (let a of this.DOM.pages) {
        a.remove();
      }
    }

    this.code = "";
    this.page = parseInt(this.page, 10);

    this.add = (s, f) => {
      for (var i = s; i < f; i++) {
        if (i < 10) {
          this.code += '<button data-pag="' + i + '" class="Apag" aria-label="Go to page 0' + i + '">0' + i + "</button>";
        } else {
          this.code += '<button data-pag="' + i + '" class="Apag" aria-label="Go to page ' + i + '">' + i + "</button>";
        }
      }
    };

    this.last = () => {
      this.code += '<span>...</span><button data-pag="' + this.max + '" class="Apag" aria-label="Go to page ' + this.max + '">' + this.max + "</button>";
    };

    this.first = () => {
      this.code += '<button data-pag="1" class="Apag" aria-label="Go to page 01">01</button><span>...</span>';
    };

    if (this.max < 8) {
      this.add(1, this.max + 1);
    } else if (this.page < 4) {
      this.add(1, 5);
      this.last();
    } else if (this.page > this.max - 3) {
      this.first();
      this.add(this.max - 3, this.max + 1);
    } else {
      this.first();
      this.add(this.page - 1, this.page + 2);
      this.last();
    }

    this.DOM.paghold.innerHTML = this.code;
    this.code = "";

    this.DOM.pages = this.DOM.paghold.querySelectorAll(".Apag");

    if (this.DOM.paghold.querySelector('.Apag[data-pag="' + this.page + '"]')) {
      this.DOM.paghold.querySelector('.Apag[data-pag="' + this.page + '"]').classList.add("act");
    }

    this.checkPos();

    for (let [i, a] of this.DOM.pages.entries()) {
      a.onclick = async () => {
        this.scrolltotop();
        // this.DOM.paghold.querySelector('.Apag.act').classList.remove('act')
        await this.loadPosts(document.querySelector("#content").dataset.cat, a.dataset.pag, 0);
      };
    }
  }

  async createComps() {
    await super.createComps();
  }

  async animIntro(val) {
    if (this.components.intro) {
      this.components.intro.start();
    }

    return val;
  }

  async animOut() {
    await gsap.to(this.DOM.el, { opacity: 0 });
  }

  checkPos() {
    this.DOM.prev.classList.remove("dis");
    this.DOM.next.classList.remove("dis");

    if (this.page == 1) {
      this.DOM.prev.classList.add("dis");
    } else if (this.page == this.max) {
      this.DOM.next.classList.add("dis");
    }
  }

  scrolltotop() {
    this.main.events.scrollto.detail.id = "blogmain";
    this.main.events.scrollto.detail.duration = 1;
    this.main.events.scrollto.detail.offset = -100;
    document.dispatchEvent(this.main.events.scrollto);
  }

  initEvents() {
    for (let a of this.DOM.cats) {
      a.onclick = async () => {
        this.DOM.searchNpt.value = "";
        if (a.dataset.id === "0") {
          for (let c of this.DOM.cats) {
            c.classList.remove("act");
          }
          this.scrolltotop();
          await this.loadPosts(0, 1, 1);
          document.querySelector("#content").dataset.cat = 0;
        } else if (a.classList.contains("act")) {
          a.classList.remove("act");
          this.scrolltotop();
          await this.loadPosts(0, 1, 1);
          document.querySelector("#content").dataset.cat = 0;
        } else {
          for (let c of this.DOM.cats) {
            c.classList.remove("act");
          }
          a.classList.add("act");
          this.scrolltotop();
          await this.loadPosts(a.dataset.id, 1, 1);
          document.querySelector("#content").dataset.cat = a.dataset.id;
        }
      };
    }

    this.DOM.prev.onclick = async (e) => {
      this.scrolltotop();
      await this.loadPosts(document.querySelector("#content").dataset.cat, this.page - 1, 0);
    };

    this.DOM.next.onclick = async (e) => {
      this.scrolltotop();
      await this.loadPosts(document.querySelector("#content").dataset.cat, this.page + 1, 0);
    };

    this.DOM.search.onsubmit = async (e) => {
      e.preventDefault();
      this.scrolltotop();
      for (let c of this.DOM.cats) {
        c.classList.remove("act");
      }

      await this.loadPosts(0, 1, 0, this.DOM.searchNpt.value);
    };
  }
}

export default Home;
