
import './index.scss'

export default class {
  constructor (obj,device,touch) {
 

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode,
      grad:obj.el.parentNode.parentNode.querySelector('.grad')
    }


    this.active = 0
    this.isupdate = 2
    
    
    this.h = window.innerHeight
    
    this.create()
  }
  
  create(){


    this.sliderpos = -1

    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      progt:0,
      total:0,
    }


    this.anim = gsap.timeline({paused:true})
  .fromTo(this.DOM.grad,
    {
    '--gradop':'0',
    '--gradsize':'0%',


    }
    ,{
    '--gradop':'.3',
    '--gradsize':'50%',
    duration:.5,ease:'none'},0)


    for(let [i,el] of this.DOM.parent.querySelectorAll('.cards_el').entries()){
      gsap.set(el,{'--b':0+'px','--r':360+'deg','--rn':-360+'deg','--d':'60vw'})
      if(i == 0){

        this.anim
        .to(el,{'--b':0+'px','--r':235+'deg','--rn':-235+'deg','--d':'60vw',duration:1 + (i*.2)},0)
      
      }
      else if(i == 1){
        
        this.anim
        .to(el,{'--b':0+'px','--r':270+'deg','--rn':-270+'deg','--d':'60vw',duration:1 + (i*.2)},0)
      
      }
      else if(i == 2){
        
        this.anim
        .to(el,{'--b':0+'px','--r':305+'deg','--rn':-305+'deg','--d':'60vw',duration:1 + (i*.2)},0)
      
      }
    }


    //ANIM
  


  }

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        
        this.stop()
      }

    return vis

  }
  
  start(){

    this.active = 1

  }
  
  stop(){
    
    this.active = 0

   

  }

  initEvents(){
   
  }
  removeEvents(){

  }

  

  update(vel,time,y){
    // console.log(vel+' '+time+' '+y)
    this.animstick.current = y - this.animstick.start

    this.animstick.current = window.clamp(0, this.animstick.limit, this.animstick.current)

    this.animstick.progt = (this.animstick.current  / this.animstick.limit).toFixed(4)
    
    this.animstick.prog = lerp(this.animstick.prog , this.animstick.progt , .02)


    this.anim.progress(this.animstick.prog)
  }
 
  onResize(){

    
    this.w = window.innerWidth
    this.h = window.innerHeight

    this.animstick.start =  parseInt((this.DOM.el.getBoundingClientRect().y - this.h + window.scrollY))
    this.animstick.limit = parseInt((this.DOM.el.clientHeight ))
    
    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    // if(this.anim){
    //   this.anim.kill()
    //   this.create()
    //   this.update(window.scrollY)
    // }


  }
  


  
}
