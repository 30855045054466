
  
  export function getRnd(max){
    return Math.floor(Math.random() * max)
  }

  export function writeCt(el){
    let fakes = '##·$%&/=€|()@+09*+]}{['
    let fakeslength = fakes.length - 1

    new window.SplitType(el, { types: 'chars,words' })

    let splits = el.querySelectorAll('.char')
    for(let [i,a] of splits.entries()){


    a.innerHTML = '<span class="n">'+a.innerHTML+'</span>'
    
    
    let rnd = 0
    for ( let u=0;u<3;u++){
      rnd = this.getRnd(fakeslength)
      a.insertAdjacentHTML('afterbegin','<span class="f">'+fakes[rnd]+'</span>')

    }

    
    el.style.opacity = 0

    
  }

  }
  export function writeFn(parnt){
    
    
    let splits = parnt.querySelectorAll('.char')

    let anim = anime.timeline({
      autoplay: false
    })

    if(parnt.classList.contains('Awrite-inv')){
      anim
      .add({
        duration:1800,
        targets:parnt,
        opacity:1,
        autoplay: false,
        easing:(el,i,t)=>{ 
          return function(t){
            return window.Power4.inOut(t) 

          }
          return t
        
        }

      },0)
    }
    else{

      parnt.style.opacity = 1
    }

    for(let [i,a] of splits.entries()){
      let n = a.querySelector('.n')

      anim
      // .add({
      //   duration:11,
      //   targets:n,
      //   opacity:0,
      //   easing:'linear',
      //   autoplay: false,

      // },0)
      .add({
        duration:600,
        targets:n,
        opacity:1,
        autoplay: false,
        easing:(el,i,t)=>{ 
          return function(t){
            return window.Power4.inOut(t) 

          }
          return t
        
        }
          // easing:'linear'
      },(i*.1) * 1000)




      for(let [u,f] of a.querySelectorAll('.f').entries()){
        anim
        .add({
          duration:1,
          targets:f,
          opacity:0,
          easing:'linear',
          autoplay: false,

        },0)
        .add({
          duration:200,
          targets:f,
          opacity:[1,0],
          scaleX:[1,0],

          easing:(el,i,t)=>{ 
            return function(t){
              return window.Power4.inOut(t) 
  
            }
            return t
          
          }

        },((i*.1) + ((1+u)*.03)) * 1000) 
        
        
        


        // console.log((i*.1) * 100)
        // console.log(((i*.1) + ((1+u)*.03)) * 100)
      }
      
    }

    anim.play()
  }



  export async function clipGrad(el,state=0,params){
    if(params != null){

      

    }
    else{
      params =[1,0,-90]
    }
    if(state == 0 ){
      
      let pos = el.getBoundingClientRect()
      gsap.set(el,{'--grads':'100%'})
      gsap.set(el,{'--grade':'100%'})
      gsap.set(el,{'--blur':'4px'})
      
      
    }
    else if(state==1){
      if(params[0] == 0){
        params[0] = 1
      }


      if(el.dataset.param0){
        params[0] = el.dataset.param0

      }

      if(el.dataset.param1){
        params[1] = el.dataset.param1

      }

      gsap.set(el,{'--direc':params[2]+'deg'})
      gsap.to(el,{'--grade':'0%',duration:params[0]*.6,delay:params[1],ease:'power2.inOut'})
      gsap.to(el,{'--grads':'0%',duration:params[0],delay:params[1],ease:'power2.inOut'})
      gsap.to(el,{'--blur':'0px',duration:params[0]*.6,delay:(params[0]*.4)+params[1],ease:'power2.in'})
    }
    else if(state==-1){
      gsap.set(el,{'--direc':params[2]+'deg'})
      gsap.to(el,{'--grade':'100%',duration:params[0],delay:params[1],ease:'power2.inOut'})
      gsap.to(el,{'--grads':'100%',duration:params[0]*.6,delay:params[1],ease:'power2.inOut'})
      gsap.to(el,{'--blur':'4px',duration:params[0]*.6,delay:params[1],ease:'power2.in'})
    }

  }
  // export async function imageReveal(el,state=0){
  //   if(state == 0 ){
      
  //     let pos = el.getBoundingClientRect()
  //     let y = 90
      
      
  //     if(pos.x < this.main.screen.w * .5){
  //       y = -90
  //     }
      
  //     if(this.main.isTouch){
  //       gsap.set(el.querySelector('img'),{scale:1.2,opacity:0})

  //     }
  //     else{
  //       if(el.classList.contains('ioesp')){
  //         gsap.set(el.querySelector('img'),{scale:1.2,opacity:0})
          
  //       }
  //       else{

  //         gsap.set(el,{rotateY:y+'deg',rotateX:-64+'deg'})
  //         gsap.set(el.querySelector('img'),{rotateY:y*-1+'deg',rotateX:64+'deg',scale:1.2})
  //         gsap.set(el.querySelector('img'),{scale:1.2})
  //       }

        
  //     }


  //   }
  //   else{
  //     let anim = gsap.timeline()
      



  //     if(this.main.isTouch){
  //       anim
  //       .to(el.querySelector('img'),{opacity:1,duration:.6,ease:'power2.inOut',onComplete:()=>{el.classList.add('end')}},.3)
  //       .to(el.querySelector('img'),{scale:1,duration:2,ease:'power2.inOut',},0)
  //     }
  //     else{
  //       if(el.classList.contains('ioesp')){
  //         anim
  //         .to(el.querySelector('img'),{opacity:1,duration:.4,ease:'power2.inOut',onComplete:()=>{el.classList.add('end')}},.15)
  //         .to(el.querySelector('img'),{scale:1,duration:1,ease:'power2.inOut',},0)
  //       }
  //       else{
          
  //         anim
  //         .to(el,{rotateY:0+'deg',rotateX:0+'deg',duration:.8,ease:'power2.inOut',onComplete:()=>{el.classList.add('end')}},.0)
  //         .to(el.querySelector('img'),{rotateY:0+'deg',rotateX:0+'deg',duration:.8,ease:'power2.inOut'},.0)
  //         .to(el.querySelector('img'),{scale:1,duration:2,ease:'power2.inOut',},.3)
        
  //       }
  //     }

  //   }


  // }



  export async function imageReveal(el,state=0){
    if(state == 0 ){
      
      let pos = el.getBoundingClientRect()
      let y = 90
      
      
      if(pos.x < this.main.screen.w * .5){
        y = -90
      }
      
      if(this.main.isTouch){
        gsap.set(el.querySelector('img'),{scale:1.2,opacity:0})

      }
      else{
        if(el.classList.contains('ioesp')){
          gsap.set(el.querySelector('img'),{scale:1.2})
          gsap.set(el.querySelector('.Aimrev_h'),{width:0,height:0})
          
        }
        else{

          gsap.set(el.querySelector('.Aimrev_h'),{width:0,height:0})
          gsap.set(el.querySelector('img'),{scale:1.2})
        }

        
      }


    }
    else{
      let anim = gsap.timeline()
      



      if(this.main.isTouch){
        anim
        .to(el.querySelector('img'),{opacity:1,duration:.6,ease:'power2.inOut',onComplete:()=>{el.classList.add('end')}},.3)
        .to(el.querySelector('img'),{scale:1,duration:2,ease:'power2.inOut',},0)
      }
      else{
        if(el.classList.contains('ioesp')){
          anim
          
          .to(el.querySelector('.Aimrev_h'),{width:100+'%',height:100+'%',duration:.45,ease:'power2.inOut',onComplete:()=>{el.classList.add('end')}},.3)
          .to(el.querySelector('img'),{scale:1,duration:1,ease:'power2.inOut',},0)
        }
        else{
          
          anim
        .to(el.querySelector('.Aimrev_h'),{width:100+'%',height:100+'%',duration:1,ease:'power2.inOut',onComplete:()=>{el.classList.add('end')}},.3)
        .to(el.querySelector('img'),{scale:1,duration:2,ease:'power2.inOut',},0)
        
        }
      }

    }


  }