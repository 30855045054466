import Page from "/js🧠🧠🧠/page👁️/pagemain.js";

//COMPS

import Intro from "./0Intro";
import Blur from "./1Blur";
import Layers from "./2Layers";
import Cards from "./3Cards";

class Home extends Page {
  constructor(main) {
    super(main);
  }

  async create(content, main, temp = undefined) {
    super.create(content, main);
    if (temp != undefined) {
      document.querySelector("#content").insertAdjacentHTML("afterbegin", temp);
    } else {
      let data = await this.loadRestApi(
        this.main.base + "/wp-json/wp/v2/pages/",
        content.dataset.id,
        content.dataset.template
      );
      document.querySelector("#content").insertAdjacentHTML("afterbegin", data.csskfields.main);
    }
    this.el = document.querySelector("main");

    this.DOM = {
      el: this.el,
    };

    await this.loadImages();
    await this.loadVideos();

    await this.createComps();
    await this.createIos();

    await this.getReady();
  }
  iOpage(animobj) {
    if (animobj.el.classList.contains("iO-blur")) {
      animobj.class = new Blur(animobj, this.main.device, this.main.isTouch);
    } else if (animobj.el.classList.contains("iO-layers")) {
      animobj.class = new Layers(animobj, this.main.device, this.main.isTouch);

      if (animobj.class.DOM.els) {
        for (let [i, a] of animobj.class.DOM.els.entries()) {
          a.onclick = async () => {
            if (animobj.class.animstick.prog < 0.96) {
              this.main.events.scrollto.detail.id = "stcklay";
              this.main.events.scrollto.detail.duration = 2;
              this.main.events.scrollto.detail.offset = 0;
              animobj.class.switchMdl(1, i);

              animobj.class.DOM.guide.classList.add("hide");
              animobj.class.anim.play();
              document.dispatchEvent(this.main.events.scrollto);
              await window.timeout(1000);
              document.dispatchEvent(this.main.events.stopscroll);
            } else {
              document.dispatchEvent(this.main.events.stopscroll);
              animobj.class.switchMdl(1, i);
            }
          };
        }
      }

      if (animobj.class.closeMdl) {
        animobj.class.closeMdl.onclick = () => {
          animobj.class.switchMdl(0, -1);
          animobj.class.DOM.guide.classList.remove("hide");
          animobj.class.anim.reverse();
          document.dispatchEvent(this.main.events.startscroll);
        };
      }
    } else if (animobj.el.classList.contains("iO-cards")) {
      animobj.class = new Cards(animobj, this.main.device, this.main.isTouch);
    }

    return animobj;
  }

  async createComps() {
    this.components.intro = new Intro(this.DOM.el.querySelector(".about_intro"), this.main.device);

    await super.createComps();
  }

  async animIntro(val) {
    gsap.set(this.el.querySelector(".about_intro"), { opacity: 1 });
    this.components.intro.start();

    return val;
  }

  async animOut() {
    await gsap.to(this.el, { opacity: 0, duration: 0.45, ease: "Power2.inOut" });
  }
}

export default Home;
