import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Start from './0Start'
import StartIo from './0Start/io.js'
import Intro from './0Intro'
import IntroIo from './0Intro/io.js'
import IntroIoT from './0Intro/ioT.js'
import StarsT from './0Intro/starsT.js'
import StatsT from './0Intro/statsT.js'


import Logos from './1Logos'
import Why from './2Why'
import Process from './3Process'
import Quotes from './4Quotes'
import Cards from './5Cards'

class Home extends Page {
  constructor (main) {
    super(main)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){
      
      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{

      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    if(animobj.el.classList.contains('iO-start')){
      animobj.class = new StartIo(animobj,this.main.device,this.main.touch,this.main.events.anim) 
      
      this.DOM.el.querySelector('.home_start #statL').onclick = async () =>{

        document.dispatchEvent(this.main.events.stopscroll)
        await animobj.class.changeStatBtn(-1)
        document.dispatchEvent(this.main.events.startscroll)
      
      }

      this.DOM.el.querySelector('.home_start #statR').onclick = async () =>{

        document.dispatchEvent(this.main.events.stopscroll)
        await animobj.class.changeStatBtn(1)
        document.dispatchEvent(this.main.events.startscroll)
      
      }
    }
    else if(animobj.el.classList.contains('iO-introT')){
      animobj.class = new IntroIoT(animobj,this.main.device,this.main.touch,this.main.events.anim) 
      

    }
    else if(animobj.el.classList.contains('iO-starsT')){
      animobj.class = new StarsT(animobj,this.main.device,this.main.touch) 
      

    }
    else if(animobj.el.classList.contains('iO-cards')){
      animobj.class = new Cards(animobj,this.main.device,this.main.touch) 
      

    }
    else if(animobj.el.classList.contains('iO-statsT')){
      animobj.class = new StatsT(animobj,this.main.device,this.main.touch,this.main.events.anim) 
      
      this.DOM.el.querySelector('.cnt_stat #statL').onclick = async () =>{

        animobj.class.changeStatBtn(-1)
      
      }

      this.DOM.el.querySelector('.cnt_stat #statR').onclick = async () =>{

        animobj.class.changeStatBtn(1)
      
      }
    }
    else if(animobj.el.classList.contains('iO-logos')){
      animobj.class = new Logos(animobj,this.main.touch) 
      

    }
    else if(animobj.el.classList.contains('iO-why')){
      animobj.class = new Why(animobj,this.main.touch) 
      

    }
    else if(animobj.el.classList.contains('iO-process')){
      animobj.class = new Process(animobj,this.main.touch) 
      

    }
    else if(animobj.el.classList.contains('iO-quotes')){
      animobj.class = new Quotes(animobj,this.main.touch) 
      

    }
    
    return animobj
  }

  
  
  async createComps(){
   


    await super.createComps()
    
    let startel = undefined
    if(this.main.device < 1){
      startel = this.DOM.el.querySelector('.home_start')
    }
    else{
      startel = this.DOM.el.querySelector('.home_heroT')
    }
    this.components.intro = new Start(startel,this.main.device,this.main.isTouch,this.main.events.anim)
    

  }


  async animIntro(val){
      
    if(this.main.isTouch == 0){
      
      await this.components.intro.start(val)
    }
    else{
      await this.components.intro.start(val)

    }
    

    return val
   
  }

  async animOut(){
    await gsap.to(this.el,{opacity:0,duration:.45,ease:'Power2.inOut'})

  }

}




export default Home