import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'

// import Introio from './1Intro/io.js'

class Home extends Page {
	constructor(main) {
		super(main)
	}

	async create(content, main, temp = undefined) {
		super.create(content, main)
		if (temp != undefined) {
			document.querySelector('#content').insertAdjacentHTML('afterbegin', temp)
		} else {
			let data = await this.loadRestApi(this.main.base + '/wp-json/wp/v2/pages/', content.dataset.id, content.dataset.template)
			document.querySelector('#content').insertAdjacentHTML('afterbegin', data.csskfields.html)
		}

		this.el = document.querySelector('main')

		this.DOM = {
			el: this.el,
		}

		await this.loadImages()
		await this.loadVideos()

		await this.createComps()
		await this.createIos()

		await this.getReady()
	}
	iOpage(animobj) {
		return animobj
	}

	async createComps() {
		this.components.intro = new Intro(this.DOM.el.querySelector('.error_intro'), this.main.device)
		await super.createComps()
	}

	async animIntro(val) {
		this.components.intro.start()
		return val
	}

	async animOut() {
		await gsap.to(this.el, {opacity: 0, duration: 0.45, ease: 'Power2.inOut'})
	}
}

export default Home
