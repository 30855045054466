

export default class {
  constructor (obj,device) {


    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode
    }
    this.device = device
    this.active = 0
    this.isupdate = 1

      
    this.create()
    
  }

  async create(){

    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
    }

    this.DOM.bggrad = this.DOM.parent.querySelector('.cnt_grad')

   this.anim = gsap.timeline({paused:true,
    
  })

  .fromTo(this.DOM.bggrad,
    {
    '--gradop':'0',
    '--gradsize':'0%',
    '--y':80+'%',


    }
    ,{
    '--gradop':'.5',
    '--gradsize':'50%',
    '--y':60+'%',
    duration:.5,ease:'none'},0)

    .to(this.DOM.bggrad,
      {
      '--y':40+'%',
      duration:.5,immediateRender:false,ease:'none'
     },.5)
    .to(this.DOM.bggrad,
     {
     '--gradop':'0',
     '--gradsize':'0%',
     duration:.15,ease:'none'
    },.85)

  }
  
  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }


  async start(){

    if(this.active==1){
      return false
    }
    this.active = 1
    
  }


  stop(){

    if(this.active==0){
      return false
    }

    this.active = 0


  }

  initEvents(){

  }
  
  removeEvents(){
    
  }

  update(pos){

    this.animstick.current = (this.h+pos) - this.animstick.start
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
    this.anim.progress(this.animstick.prog)

  }


  onResize(){
    this.h = window.innerHeight

    this.animstick.start = (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    this.animstick.limit = (this.DOM.el.clientHeight + this.h).toFixed(0)

    this.update(window.scrollY)
  }
}
