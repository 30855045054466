

export default class {
  constructor (obj,device) {


    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode
    }
    this.device = device
    this.active = 0
    this.isupdate = 2

      
    this.create()
    
  }

  async create(){
    
    this.DOM.main = this.DOM.parent
    this.DOM.els = this.DOM.parent.querySelectorAll('.cnt_el')
    this.DOM.bgs = this.DOM.parent.querySelectorAll('.cnt_el_grad')

    this.posxy = [
      0,0,0,0
    ]


    this.target = [
      0,0
    ]
    this.current = [
      0,0
    ]


    this.anim = gsap.timeline({
      paused:true,
    
    })

    for(let [i,a] of this.DOM.bgs.entries()){
      this.anim
      
      .fromTo(a,
      {
      '--gradop':'0',
      '--gradsize':'0%',


      }
      ,{
      '--gradop':'.2',
      '--gradsize':'50%',
      immediateRender:false,
      duration:.5,
      ease:'none'},0)
    }
   
  }
  
  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }


  async start(){

    if(this.active==1){
      return false
    }
    this.active = 1
    
  }


  stop(){

    if(this.active==0){
      return false
    }

    this.active = 0


  }

  initEvents(){

    this.DOM.el.onmouseenter = (e)=>{
      this.anim.pause()
      this.anim.timeScale(1)
      this.anim.play()



      this.current[0] = e.clientX
      this.current[1] = e.layerY
      this.target[0] = e.clientX
      this.target[1] = e.layerY
      
      this.in = 1
    
    
    }
    this.DOM.el.onmousemove = (e)=>{

      this.target[0] = e.clientX
      this.target[1] = e.layerY

    }
    this.DOM.el.onmouseleave = (e)=>{
      this.anim.pause()
      this.anim.timeScale(1.6)
      this.anim.reverse()

      this.in = 0
    }

  }
  
  removeEvents(){
    
  }

  update(vel,time,y){

    if(this.in == 1){
      this.current[0] = lerp(this.current[0], this.target[0], .2)
      this.current[1] = lerp(this.current[1], this.target[1], .2)


      gsap.set(this.DOM.bgs[0],{'--x':(this.current[0] - this.posxy[0])+'px','--y':(this.current[1])+'px'})
      gsap.set(this.DOM.bgs[1],{'--x':(this.current[0] - this.posxy[2])+'px','--y':(this.current[1])+'px'})


    }

    
  }


  onResize(){
    let f = this.DOM.els[0].getBoundingClientRect()
    let s = this.DOM.els[1].getBoundingClientRect()

    this.posxy = [f.x,f.y,s.x,s.y]
    

  }
}
