
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch) {

    this.pos = obj.pos
    this.device = device
    this.touch = touch


    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode,
      canvas:obj.el.parentNode.querySelector('.stars'),
      tabs:obj.el.parentNode.parentNode.querySelectorAll('.cnt_tabs .el'),
      tabsc:obj.el.parentNode.parentNode.querySelectorAll('.cnt_tabs .el_plus'),
    }




    this.active = 0
    this.isstarted = 0
    this.isupdate = 2

    this.w = window.innerWidth
    this.h = window.innerHeight
    

    this.mouse = {x:0,y:0}
    this.circles = []
    this.dpr = window.devicePixelRatio || 1
    this.ctx = this.DOM.canvas.getContext('2d')


    

    this.create()
  }
   
  async create(){

   
    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
    }
    // MAIN ANIM
      //Titles
    this.autos = 
    {
      canvas:0,
      stars:1,
      zoom:2,
      nums:0,
    }

    this.anim = gsap.timeline({paused:true,
      onComplete:()=>{
      }
    })
    .fromTo(this.DOM.canvas,{opacity:0},{opacity:1,duration:1,ease:'power2.inOut'},.5)
    .fromTo(this.autos,{canvas:0,zoom:1},{canvas:1,zoom:0,duration:1,immediateRender:false,ease:'power2.inOut'},.5)
    .fromTo(this.autos,{stars:1},{stars:0,duration:.5,immediateRender:false},4.3)
    .fromTo(this.autos,{nums:0},{nums:1,duration:.5,immediateRender:false},4.3)
    
    this.drawCircles()
  }

  createAnim(){
    
  }

  killAnim(){

  }

  

  check(entry,pos){
    let vis = false
      if(entry.isIntersecting == true){
        vis = true
        this.start(pos)

      }
      else{
        this.stop(pos)
      }
    return vis

  }
  
  start(pos=0){
    if(this.active==1){
      return false
    }
    
    this.active = 1


  }
  stop(pos = null){
    // console.log('stop intro'+this.active)

    if(this.active==0){
      return false
    }

    this.active = 0



    // this.killAnim()
    // this.anim.progress(0)
    // console.log('stop intro change'+this.active)

   

  }
  removeEvents(){
  
  }
  


  update(vel,time){


    this.animstick.current = window.scrollY - this.animstick.start + this.h
    this.animstick.current = window.clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(5)
 

    this.anim.progress(this.animstick.prog)

    if(this.autos.canvas != 0){
      this.animateCircles(this.autos)
    }

  }


  onResize(pos){
    this.w = window.innerWidth
    this.h = window.innerHeight


    this.animstick.start = parseInt((this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0))
    this.animstick.limit = this.DOM.el.clientHeight
    
    if(this.DOM.canvas ){
      this.resizeCanvas()
    }

    
  }







  drawCircles(){
    this.ctx.clearRect(0, 0, this.w, this.h)

    const particleCount = 42
    
    for (let i = 0; i < particleCount; i++) {
      const circle = this.cxParams(i)
      this.drawCircle(circle,0)
    }


  }

  remapValue(value, start1, end1, start2, end2) {
    const remapped = (value - start1) * (end2 - start2) / (end1 - start1) + start2;
    return remapped > 0 ? remapped : 0;
  }


  animateCircles(ops) {

    this.ctx.clearRect(0, 0, this.w, this.h)
    this.circles.forEach((circle, i) => {
      
      const edge = [
        circle.x + circle.translateX - circle.size, // distance from left edge
        this.w - circle.x - circle.translateX - circle.size, // distance from right edge
        circle.y + circle.translateY - circle.size, // distance from top edge
        this.h - circle.y - circle.translateY - circle.size, // distance from bottom edge
      ]
      const closestEdge = edge.reduce((a, b) => Math.min(a, b))
      const remapClosestEdge = this.remapValue(closestEdge, 0, 20, 0, 1).toFixed(2)
      if (remapClosestEdge > 1) {
        circle.alpha += 0.02
        if (circle.alpha > circle.targetAlpha) circle.alpha = circle.targetAlpha
      } 
      else {
        circle.alpha = circle.targetAlpha * remapClosestEdge
      }

      circle.x += circle.dx * .3
      circle.y += circle.dy * .3
      circle.translateX += ((this.mouse.x / (50 / circle.magnetism)) - circle.translateX) / 50
      circle.translateY += ((this.mouse.y / (50 / circle.magnetism)) - circle.translateY) / 50
      
      if (circle.x < -circle.size || circle.x > this.w + circle.size || circle.y < -circle.size || circle.y > this.h + circle.size) {
        this.circles.splice(i, 1)
        const circle = this.cxParams(i)
        this.drawCircle(circle,ops)
        this.drawCircle({ ...circle, x: circle.x, y: circle.y, translateX: circle.translateX, translateY: circle.translateY, alpha: circle.alpha },ops, true)
        
      }else {
        this.drawCircle({ ...circle, x: circle.x, y: circle.y, translateX: circle.translateX, translateY: circle.translateY, alpha: circle.alpha }, ops, true)
      }

      if(i == 0){

      }
    })

  }

  resizeCanvas() {
    // this.circles.length = 0
    this.DOM.canvas.width = this.w * this.dpr
    this.DOM.canvas.height = this.h * this.dpr
    this.DOM.canvas.style.width = this.w+'px'
    this.DOM.canvas.style.height = this.h+'px'
    this.ctx.scale(this.dpr, this.dpr)


  }



  cxParams(i){
    // const x = Math.floor(Math.random() * this.w)
    // const y = Math.floor(Math.random() * this.h)



    let x = Math.floor(Math.random() * (this.w) )

    let sumx = 0
    sumx = Math.floor(sumx)

    let zoomx = 0
    let zoomy = 0


    let y = Math.floor(Math.random() * (this.h * .8) ) + (this.h * .2) 

    
    // let sumy = Math.floor(Math.random() * (this.w * .3) ) + (this.w * .1)
    let sumy = 0


   

    
  

    const translateX = 0
    const translateY = 0
    const size = Math.floor(Math.random() * 4) + 1
    const alpha = 0
    const targetAlpha = parseFloat((Math.random() * 0.1 + 0.2).toFixed(1))

    const dx = (Math.random() - 0.5) * 0.2
    const dy = (Math.random() - 0.5) * 0.2
    const magnetism = 0.1 + Math.random() * 4



    const arr = ['01','10','00','11','000','101','100','001']

    const val = arr[Math.floor(Math.random() * arr.length)]

    return { x, y, translateX, translateY, size, alpha, targetAlpha, dx, dy, magnetism,zoomx,zoomy,val }


  }


  drawCircle(circle,ops,update = false){
    const { x, y, translateX, translateY, size, alpha,zoomx,zoomy,val } = circle




    if(ops.stars > 0){

      this.ctx.translate(translateX, translateY)
      this.ctx.beginPath()

      // console.log(zoomx*ops.zoom)
      this.ctx.arc(x-(zoomx*ops.zoom), y-(zoomy*ops.zoom), size*( ops.stars ), 0, 2 * Math.PI)
      this.ctx.fillStyle = `rgba(255, 255, 255, ${alpha * ( ops.stars )})`
      this.ctx.fill()
      this.ctx.setTransform(this.dpr, 0, 0, this.dpr, 0, 0)

    }
    if(ops.nums > 0){
      this.ctx.beginPath()
      this.ctx.fillStyle = `rgba(255, 255, 255, ${alpha * ( ops.nums * 2 )})`
      this.ctx.font = (8+size)*( ops.nums )+'px Darker'
      this.ctx.fillText(val, x-5, y+5)
      this.ctx.setTransform(this.dpr, 0, 0, this.dpr, 0, 0)

      
    }

    if (!update) {
      this.circles.push(circle)
    }
  }



  initEvents(){

    if(this.DOM.tabsc){

      for(let [i,a] of this.DOM.tabsc.entries()){

        a.onclick = () =>{
          for(let u=0;u<this.DOM.tabsc.length;u++){
            if(i == u){
              
              this.DOM.tabs[u].classList.toggle('act')
            }
            else{

              this.DOM.tabs[u].classList.remove('act')
            }
          }
        }

      }
    }
  }
}
