
import {Swiper} from 'swiper'

export default class {
  constructor (obj,device,touch) {
 

    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
    }

    
    this.create()
  }
  
  create(){

    this.isanim = 0
    this.sliderpos = -1

    this.DOM.slides = this.DOM.el.parentNode.querySelectorAll('.cnt_slides_el')
  
    this.swiper = new Swiper(this.DOM.el.parentNode.querySelector('.swiper'),{
      slidesPerView: 'auto',
      centeredSlides:true,
      loop:false,
      allowTouchMove: false,
      watchSlidesProgress: true,
      speed:900,

    })
    this.DOM.l = this.DOM.el.parentNode.querySelector('#CasesL')
    this.DOM.l.classList.add('inact')
    this.DOM.r = this.DOM.el.parentNode.querySelector('#CasesR')





  //   this.DOM.bggrad = this.DOM.el.parentNode.querySelector('.grad')
  //   this.anim = gsap.timeline({paused:true,
    
  //   })
  // .fromTo(this.DOM.bggrad,
  //   {
  //   '--gradop':'0',
  //   '--gradsize':'0%',
  //   '--y':80+'%',


  //   }
  //   ,{
  //   '--gradop':'.5',
  //   '--gradsize':'50%',
  //   '--y':60+'%',
  //   duration:.5,ease:'none'},0)



  }

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        
        this.stop()
      }

    return vis

  }
  
  start(){
    if(this.sliderpos == -1){ 
      this.DOM.slides[0].classList.add('act')
      this.sliderpos = 0
    }


  }
  
  stop(){
    
    this.active = 0

   

  }

  initEvents(){
    this.DOM.l.onclick = () => {
      if(this.isanim == 1 || this.swiper.isBeginning){
        return false
      }
      console.log('patra')
      this.isanim = 1
      this.swiper.slidePrev()
    }
    this.DOM.r.onclick = () => {
      if(this.isanim == 1 || this.swiper.isEnd){
        return false
      }
      this.isanim = 1
      this.swiper.slideNext()
 
    }

    this.swiper.on('slideChangeTransitionEnd',()=>{
      this.isanim = 0

      if(this.swiper.isBeginning){
        this.DOM.l.classList.add('inact')
      }
      else{
        
        this.DOM.l.classList.remove('inact')
      }


      if(this.swiper.isEnd){
        this.DOM.r.classList.add('inact')
      }
      else{
        
        this.DOM.r.classList.remove('inact')
      }
    })

  }
  removeEvents(){

  }

  

  update(){
  }
 
  onResize(){

    

  }
  


  
}
