
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch,animev) {

    this.pos = obj.pos
    this.device = device
    this.touch = touch

    this.animev = animev
    
    this.DOM = {
      el:obj.el,
      parent:obj.el.parentNode,
      bggrad:obj.el.parentNode.querySelector('.bg_grad'),
    }




    this.active = 0
    this.isstarted = 0
    this.isupdate = 2
    this.time  = performance.now()

    this.h = window.innerHeight
    
    //TT
    this.postit = 0
    this.ttpos = -1


    //Stats
    
    this.statpos = -1
    

    this.create()
  }
   
  async create(){

    // MAIN ANIM
      //Titles
    this.autos = 
    {
      tt:0,
    }

    this.times = 
    [
      this.time,
      
    ]
    this.delays = 
    [
      1000 * 3.6,
      
    ]

    const scr = this.DOM.parent.querySelector('.scrme_l')
    
    this.scranim = gsap.timeline({paused:true,repeat:-1,repeatDelay:1,
      
    })
    .fromTo(scr,{
      scaleY:0
    },{
      scaleY:1,
      duration:.6,
    },0)
    .fromTo(scr,{
      opacity:1,
      scaleY:1,
      transformOrigin:'50% 0%',
    },{
      opacity:0,
      scaleY:0,
      transformOrigin:'50% 100%',
      delay:.4,
      duration:1,
    },.6)
    
  }

  createAnim(){
    
  }

  killAnim(){

  }

  

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }
  
  start(){
    if(this.active==1){
      return false
    }
    if(this.isstarted == 0){
      this.startAnim()
    }
    else{
      this.scranim.play()
      let time = performance.now()
      for(let [i,t] of this.times.entries()){
          if(i == 0){
            this.times[i] = time

          }
          else if(i == 1){
            if(this.autos.stat == 1){

              this.times[1] = performance.now() + this.delays[1]

            }
          }
          
  
      }
    }
    this.active = 1


  }
  stop(){
    // console.log('stop intro'+this.active)

    if(this.active==0){
      return false
    }
    this.scranim.pause()

    this.active = 0



    // this.killAnim()
    // this.anim.progress(0)
    // console.log('stop intro change'+this.active)

   

  }
  async startAnim(){
   
    this.changeSlide(1)
      
    await gsap.to(this.DOM.bggrad,
    {
      '--gradop':'.3',
      '--gradsize':'50%',
      duration:3,
      ease:'power2.inOut'
    })

    this.DOM.parent.querySelector('.scrme').classList.add('act')

    this.scranim.play()
    gsap.set(this.autos,{tt:1})
    this.isstarted = 1

    // setTimeout(() => {
    //   this.anim.progress(1)
      
    // }, 200)
  }
  initEvents(){
   
      


    this.changeSlide = (act=this.autos.tt) => {

      if(act == 0 || document.hidden){
        return true
      }

      let lastpos = this.ttpos
      const slidestt = this.DOM.parent.querySelectorAll('.cnt_slider_el')
      
      let delayc = .6
      let delayh = .6
      
      if(this.ttpos == -1){
        delayc = 1
        delayh = 0
      }
      
      this.ttpos++

      let length = slidestt[0].querySelectorAll('span').length

      if(this.ttpos == length){
        this.ttpos = 0
      }
     
      
      
      for(let a of slidestt){
        // EL QUE TOCA
        if(lastpos == -1){
            let changespan = a.querySelectorAll('span')[this.ttpos]
              changespan.classList.add('a')

              setTimeout(()=>{

                a.style.width = changespan.clientWidth+'px'

              },delayh*1000)

              this.animev.detail.state = 1
              this.animev.detail.params = [1.4,delayc,-90]
              this.animev.detail.el = changespan.parentNode
              document.dispatchEvent(this.animev)
        }
        else{
          if(a.querySelectorAll('span')[this.ttpos].innerHTML != a.querySelectorAll('span')[lastpos].innerHTML){

            if(a.querySelector('span.a')){
            
                this.animev.detail.state = -1
                this.animev.detail.params = [.6,0,90]
                this.animev.detail.el = a.querySelector('span.a').parentNode
                document.dispatchEvent(this.animev)
                a.querySelector('span.a').classList.remove('a')
                
              // EL HIDE
              
            }
            
              // EL SHOW
              let changespan = a.querySelectorAll('span')[this.ttpos]
              changespan.classList.add('a')

              setTimeout(()=>{

                a.style.width = changespan.clientWidth+'px'

              },delayh*1000)

              this.animev.detail.state = 1
              this.animev.detail.params = [1.4,delayc,-90]
              this.animev.detail.el = changespan.parentNode
              document.dispatchEvent(this.animev)
          
          }
        }
        }
        
        

    }

    // this.changeSlide()

    // this.changeSlide()
    // setInterval(()=>
    //   this.changeSlide()

    // ,4600)
   
    if(!this.touch ){
      // window.addEventListener('focus',this.startFn)
      
    }

  }
  removeEvents(){
    if(!this.touch ){
      // window.removeEventListener('focus',this.startFn)
      
    }

  }
  

  update(vel,time,y){

     for(let [i,t] of this.times.entries()){
      if(time == -1){
        continue
      }
      if(i == 0){

        if(time - t >= this.delays[i]){
          this.changeSlide()
          this.times[i] = time
        }


      }
     
      

    }



  }


  onResize(pos){
    this.w = window.innerWidth
    this.h = window.innerHeight



    // if(this.anim){
    //   this.anim.kill()
    //   this.create()
    //   this.update(window.scrollY)
    // }


    if(this.ttpos != -1 ){
      const slidestt = this.DOM.parent.querySelectorAll('.cnt_slider_el')

      for(let a of slidestt){
        if(a.querySelector('a.span')){

          let changespan = a.querySelector('a.span')
          a.style.width = changespan.clientWidth+'px'

        }

      }
    }
    
  }
}
