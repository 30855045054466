
import './index.scss'

import './mdl.scss'



class Nav {
  constructor (main) {
   
    this.main = main
    this.isOpen = 0

  }

  async create (temp) {

    document.querySelector('body').insertAdjacentHTML('afterbegin',temp)

   let el = document.querySelector('.nav')
    this.DOM = {
      el:el,
      burger:el.querySelector('.nav_burger'),
      logo:el.querySelector('.nav_logo'),
      menu:el.querySelector('.nav_menu'),
      book0:el.querySelector('.CAL0'),
      book1:el.querySelector('.CAL1')
    }


    this.DOM.el.style.opacity = 0
   this.last = 1
   this.avoid = 0

    this.initEvents()
  }
  async closeBook(){
    const mdlbox = document.querySelector('.mdl')
    mdlbox.classList.remove('act')
    await this.timeout(460)

    mdlbox.remove()



    document.dispatchEvent(this.main.events.startscroll)
  }

  async openBook(){
    const html = '<div class="mdl"><div class="mdl_bg"></div><div class="cnt"><button class="mdlClose" aria-label="close modal"><svg id="closecalendy" viewBox="0 0 16 16"><polygon points="8 9,9 8,9 8,8 9"></polygon><polygon points="8 9,9 8,9 8,8 9"></polygon></svg></button></div></div>'
    document.querySelector('body').insertAdjacentHTML('afterbegin',html)

    const mdlbox = document.querySelector('.mdl')
    
    document.dispatchEvent(this.main.events.stopscroll)
    await this.timeout(12)


    mdlbox.classList.add('act')
    await Calendly.initInlineWidget({
      url: 'https://calendly.com/cyberconvoy/book-demo?background_color=595571&text_color=ffffff&primary_color=ffffff',
      parentElement: mdlbox.querySelector('.cnt'),
      prefill: {},
      utm: {}
     })


     let objbrg = {
      c1l:8,
      c1r:8,
      c2l:8,
      c2r:8,
    }
    const bg = mdlbox.querySelector('.mdl_bg')
    bg.onclick = () =>{
      this.closeBook()
    }

    const burger = mdlbox.querySelector('.mdlClose')
    burger.onclick = () =>{
      this.closeBook()
    }

     const brgc = mdlbox.querySelectorAll('#closecalendy polygon')
     let canim = gsap.timeline({
    })
    .fromTo(brgc[0].parentNode,{opacity:0},{opacity:1,duration:.4,ease:'power2.inOut'},0)
    
    .fromTo(objbrg,{c1l:0},{c1l:8,duration:.6,ease:'power2.inOut'},0)
    .fromTo(objbrg,{c1r:8},{c1r:0,duration:1,ease:'power2.inOut'},0)

    .fromTo(objbrg,{c2l:0},{c2l:8,duration:1,ease:'power2.inOut'},0)
    .fromTo(objbrg,{c2r:8},{c2r:0,duration:.6,ease:'power2.inOut'},0)


    const brganim = gsap.timeline({
      onUpdate:()=>{

        brgc[0].setAttribute('points',(8 - objbrg.c1l)+' '+(9 - objbrg.c1l)+','+(9 - objbrg.c1l)+' '+(8 - objbrg.c1l)+','+(16 - objbrg.c1r)+' '+(15 - objbrg.c1r)+','+(15 - objbrg.c1r)+' '+(16 - objbrg.c1r))
        
        
        brgc[1].setAttribute('points',
        (8 - objbrg.c2l)+' '+(7 + objbrg.c2l)+','
        +(9 - objbrg.c2l)+' '+(8 + objbrg.c2l)+','
        +(8 + (8 - objbrg.c2r))+' '+(1 + objbrg.c2r)+','
        +(15 - objbrg.c2r)+' '+objbrg.c2r)
      
      }
    })
    .add(canim,0)
  }
  async openMenu(){
   

    document.documentElement.classList.add('act-menu')
    if(!this.main.isTouch){
      gsap.to(this.DOM.menu,{x:0+'rem',delay:.2,duration:.6,ease:'power2.inOut'})
      gsap.to(this.DOM.burger,{x:7.4+'rem',duration:1,ease:'power2.inOut'})
    }
    else{
      this.brganim.play()
    }
    if(this.main.device > 1){
      document.dispatchEvent(this.main.events.openmenu)
    }
    this.isOpen = 1
  }
  
  async closeMenu(){
    document.documentElement.classList.remove('act-menu')
    if(!this.main.isTouch){
    
      gsap.to(this.DOM.menu,{x:6.4+'rem',duration:.6,ease:'power2.inOut'})
      gsap.to(this.DOM.burger,{x:0+'rem',delay:.2,duration:.6,ease:'power2.inOut'})
    }
    else{
      this.brganim.reverse()
    }
    if(this.main.device > 1){
      document.dispatchEvent(this.main.events.closemenu)
    }
    this.isOpen = 0
    
  }

  async show(){

    let objbrg = {
      ol:12,
      or:12,
    }

    let x = 9

    let oanim = gsap.timeline({
      paused:true,
      onUpdate:()=>{

        this.brgo[0].setAttribute('points',(objbrg.ol)+' 0,'+(objbrg.ol)+' 2,'+(objbrg.or)+'  2,'+(objbrg.or)+' 0')
        this.brgo[1].setAttribute('points',(objbrg.ol)+' 9,'+(objbrg.ol)+' 11,'+(objbrg.or)+' 11,'+(objbrg.or)+' 9')
        this.brgo[2].setAttribute('points',(objbrg.ol)+' 18,'+(objbrg.ol)+' 20,'+(objbrg.or)+' 20,'+(objbrg.or)+' 18')

      }
    })
    .to(this.DOM.el,{opacity:1,duration:.6},1.6)
    .fromTo(this.DOM.el.querySelector('.nav_logo'),{x:x*-1+'rem'},{x:0+'rem',duration:1,ease:'power2.inOut'},1.4)
    .fromTo(this.DOM.burger,{x:x+'rem'},{x:0+'rem',duration:1,ease:'power2.inOut'},1.4)
    .fromTo(objbrg,{ol:12,or:12},{ol:1,or:24,duration:.6,ease:'power2.inOut'},2)
    
    oanim.progress(0)
    await oanim.play()

    await window.timeout(100)
    
    if(!this.main.isTouch){

      document.documentElement.classList.add('act-menu')
      gsap.fromTo(this.DOM.menu,{x:-6.4+'rem'},{x:0+'rem',delay:.2,duration:.6,ease:'power2.inOut'})
      await gsap.to(this.DOM.burger,{x:6.4+'rem',duration:1,ease:'power2.inOut'})
      

      this.isOpen = 1
    
    }
  }
  async hide(){
    
    this.DOM.el.style.opacity = 0
  }

  initEvents(){
    let objbrg = {
      ol:0,
      or:0,
      c1l:8,
      c1r:8,
      c2l:8,
      c2r:8,
    }
    
    this.brgo = this.DOM.el.querySelectorAll('#openbrg polygon')
    this.brgc = this.DOM.el.querySelectorAll('#closebrg polygon')

    let oanim = gsap.timeline({
    })
    .fromTo(objbrg,{ol:1,or:24},{ol:12,or:12,duration:.6,ease:'power2.inOut'},0)
    
    let canim = gsap.timeline({
    })
    .fromTo(this.brgc[0].parentNode,{opacity:0},{opacity:1,duration:.4,ease:'power2.inOut'},.6)
    
    .fromTo(objbrg,{c1l:0},{c1l:8,duration:.6,ease:'power2.inOut'},.6)
    .fromTo(objbrg,{c1r:8},{c1r:0,duration:1,ease:'power2.inOut'},.6)

    .fromTo(objbrg,{c2l:0},{c2l:8,duration:1,ease:'power2.inOut'},.6)
    .fromTo(objbrg,{c2r:8},{c2r:0,duration:.6,ease:'power2.inOut'},.6)

    
    this.brganim = gsap.timeline({paused:true,
      onUpdate:()=>{

        this.brgo[0].setAttribute('points',(objbrg.ol)+' 0,'+(objbrg.ol)+' 2,'+(objbrg.or)+'  2,'+(objbrg.or)+' 0')
        this.brgo[1].setAttribute('points',(objbrg.ol)+' 9,'+(objbrg.ol)+' 11,'+(objbrg.or)+' 11,'+(objbrg.or)+' 9')
        this.brgo[2].setAttribute('points',(objbrg.ol)+' 18,'+(objbrg.ol)+' 20,'+(objbrg.or)+' 20,'+(objbrg.or)+' 18')

        this.brgc[0].setAttribute('points',(8 - objbrg.c1l)+' '+(9 - objbrg.c1l)+','+(9 - objbrg.c1l)+' '+(8 - objbrg.c1l)+','+(16 - objbrg.c1r)+' '+(15 - objbrg.c1r)+','+(15 - objbrg.c1r)+' '+(16 - objbrg.c1r))
        
        
        this.brgc[1].setAttribute('points',
        (8 - objbrg.c2l)+' '+(7 + objbrg.c2l)+','
        +(9 - objbrg.c2l)+' '+(8 + objbrg.c2l)+','
        +(8 + (8 - objbrg.c2r))+' '+(1 + objbrg.c2r)+','
        +(15 - objbrg.c2r)+' '+objbrg.c2r)
        // this.brgc[1].setAttribute('points',(8 - objbrg.cl)+' '+(15 - objbrg.cl)+','(9 - objbrg.cl)+' '(16 - objbrg.cl)+','(16 - objbrg.cr)+' '(9 - objbrg.cr)+','(9 - objbrg.cr)+' '(9 - objbrg.cr))
        // console.log(objbrg)
        // console.log(this.brgc)
      }
    })
    .add(oanim,0)
    .add(canim,0)

    
    // setTimeout(()=>{
    //   oanim.play()
    // },1200)
    if(this.DOM.book0){
      this.DOM.book0.onclick = () =>{
        this.openBook()
      }
    }
    if(this.DOM.book1){
      this.DOM.book1.onclick = () =>{
        this.openBook()
      }
    }
    if(this.DOM.burger){
      this.DOM.burger.onclick = async() =>{
        this.avoid = 1
        if(this.isOpen == 1){
          this.closeMenu()
        }
        else{
          this.openMenu()

          
          
        }

        await this.timeout(460)
        this.avoid = 0
      }
    }
    
  }
  

  onResize(){
    
  }
  update(time,speed){
    if(speed == 0 || this.avoid == 1){

      return false
    }


    // if(this.last > 0 && speed > 0){
    //   this.last = -1
    //   gsap.to(this.DOM.logo,{opacity:0,duration:.45,ease:'power2.inOut'})
    // }
    // else{
    //   if(this.last < 0 && speed < 0){
    //     this.last = 1
    //     gsap.to(this.DOM.logo,{opacity:1,duration:.45,ease:'power2.inOut'})
    //   }
    // }



    let sp = speed
    if(sp > 3){
      if(this.isOpen == 0){
        return false
      }
      this.closeMenu()
      
      
    }
    else if(sp < -3){

      if(this.isOpen == 1){
        return false
      }
      else if(this.isOpen == 0){

        this.openMenu()
      }

    }
  }


  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}



export default Nav