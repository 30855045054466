
import Field from '/atoms🧿🧿🧿/Field'
import Chk from '/atoms🧿🧿🧿/Chk'
export default class {
	constructor(el,base) {

		this.DOM = {
			el: el,
      form:el.querySelector('form')
		}

    this.base = base

		this.h = window.innerHeight


	}

	async create() {
    const interests = await fetch(this.base+'/wp-json/wp/v2/newsletterget?getlist=1')
		const data = await interests.json()

    // LOS INTERESTS, QUE SE COGEN PARA EL FORM
    const ul = this.DOM.el.querySelector('.Acard_form_list')
    
    for(let [i,a] of data.interests.entries()){

			ul.insertAdjacentHTML('beforeend', '<li class="Achk Achk-not"><input class="Achk_npt Interests" type="checkbox" name="list'+i+'" id="'+a.id+'" aria-label="'+a.name+'"><label class="Achk_lbl" for="list'+i+'">'+a.name+'</label></li>')
      
    }


    // METER LOS FIELDS CON SUS CLASES
    // this.fields
    if(this.DOM.el.querySelectorAll('.Afield')){
      this.fields = []
      for(let b of this.DOM.el.querySelectorAll('.Afield')){
        this.fields.push(new Field(b))
      }
    }
    if(this.DOM.el.querySelectorAll('.Achk')){
      for(let b of this.DOM.el.querySelectorAll('.Achk')){
        if(b.classList.contains('Achk-not')){
          continue
        }
        this.fields.push(new Chk(b))
      }
    }
    if(this.DOM.el.querySelectorAll('.Achk')){
      this.checks = []
      for(let b of this.DOM.el.querySelectorAll('.Achk')){
        this.checks.push(new Chk(b))
      }
    }

  }

  initEvents(){


    this.DOM.form.onsubmit = async(e) =>{
      e.preventDefault()

      for(let a of this.fields){
        a.check()
      }
      if(this.DOM.form.querySelector('.err')){
        return false
      }
      
      let info = []
      
      for(let a of this.fields){
        if(a.DOM.el.classList.contains('formEl')){
          let ob = {label:a.DOM.npt.name, value:a.DOM.npt.value}
          info.push(ob)
          
        }
      }

      let intersChecked = ''
      if(this.DOM.form.querySelectorAll('.Interests:checked')){
        
        for(let a of this.DOM.form.querySelectorAll('.Interests:checked')){
          intersChecked+=','+a.id
        }

        intersChecked = intersChecked.substring(1)
      }
      let ob = {label:'interests', value:intersChecked}
      info.push(ob)

     
      let urlfields = ""
      for(let [i,a] of info.entries()){
        if(i == info.length-1){
          urlfields+=a.label+'='+a.value
        }else{
          urlfields+=a.label+'='+a.value+'&'
        }
      }

      const formfetch = await fetch(this.base+'/wp-json/wp/v2/newsletterget?'+urlfields)
 
      const data = await formfetch.json()

      if(data.response.code === 200){
        await gsap.to(this.DOM.form,{opacity:.5,duration:.5, onComplete:()=>{
          for(let a of this.fields){
            a.clear()
          }
          for(let a of this.checks){
            a.clear()
          }
        }})
        gsap.to(this.DOM.form,{opacity:1,duration:.5,delay:1})


      }else{
        await gsap.to(this.DOM.form.querySelector('.Acard_form_msg'),{opacity:1,duration:.5})
        gsap.to(this.DOM.form.querySelector('.Acard_form_msg'),{opacity:0,duration:.5,delay:1})
      }
      
      //El check del botón con el then

    }


  }

  removeEvents(){

  }

}