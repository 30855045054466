
import './index.scss'

export default class {
  constructor (obj,device,touch) {
 
    obj.el.parentNode.setAttribute('id','stck'+obj.pos)

    if(obj.el.dataset.prior){
      this.prior = parseInt(obj.el.dataset.prior)
    }

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      parent:document.querySelector('#stck'+obj.pos),
    }


    this.active = 0
    // this.isupdate = 1
    
    this.modalst = 0
    
    this.h = window.innerHeight
    this.DOM.els = this.DOM.parent.querySelectorAll('.el')
    this.DOM.ttips = this.DOM.parent.querySelectorAll('.ttip')
    this.DOM.nfo = this.DOM.parent.querySelectorAll('.about_layers_nfo .mod')
    this.DOM.names = this.DOM.parent.querySelectorAll('.cnt_layers_names h3')
    this.DOM.guide = this.DOM.parent.querySelector('.cnt_layers_names h4')

    if(this.DOM.parent.querySelector('.modClose')){
      this.mdlBox = this.DOM.parent.querySelector('.about_layers_mdl')
      this.closeMdl = this.DOM.parent.querySelector('.modClose')
      this.brgc = this.DOM.parent.querySelectorAll('#closemdl polygon')

    }
    
    this.create()
  }
  
  create(){
    if(this.brgc){


      let objbrg = {
        c1l:8,
        c1r:8,
        c2l:8,
        c2r:8,
      }

      //BURGER
      let canim = gsap.timeline({
      })
      .fromTo(this.brgc[0].parentNode,{opacity:0},{opacity:1,duration:.4,ease:'power2.inOut'},0)
      
      .fromTo(objbrg,{c1l:0},{c1l:8,duration:.6,ease:'power2.inOut'},0)
      .fromTo(objbrg,{c1r:8},{c1r:0,duration:1,ease:'power2.inOut'},0)

      .fromTo(objbrg,{c2l:0},{c2l:8,duration:1,ease:'power2.inOut'},0)
      .fromTo(objbrg,{c2r:8},{c2r:0,duration:.6,ease:'power2.inOut'},0)


      this.brganim = gsap.timeline({paused:true,
        onUpdate:()=>{

          this.brgc[0].setAttribute('points',(8 - objbrg.c1l)+' '+(9 - objbrg.c1l)+','+(9 - objbrg.c1l)+' '+(8 - objbrg.c1l)+','+(16 - objbrg.c1r)+' '+(15 - objbrg.c1r)+','+(15 - objbrg.c1r)+' '+(16 - objbrg.c1r))
          
          
          this.brgc[1].setAttribute('points',
          (8 - objbrg.c2l)+' '+(7 + objbrg.c2l)+','
          +(9 - objbrg.c2l)+' '+(8 + objbrg.c2l)+','
          +(8 + (8 - objbrg.c2r))+' '+(1 + objbrg.c2r)+','
          +(15 - objbrg.c2r)+' '+objbrg.c2r)
        
        }
      })
      .add(canim,0)

    }

    //BUTTONS
    this.sliderpos = -1

    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      total:0,
    }

    this.shinepos = 0
    this.lgth = this.DOM.els.length - 1
    this.createShine = () =>{

      this.DOM.els[this.shinepos].classList.remove('showanim')

      this.shinepos = Math.floor(Math.random() * (this.lgth) )

      this.DOM.els[this.shinepos].classList.add('showanim')

    }

    //ANIM
    this.repanim = gsap.set(this.createShine, {delay: 1, onRepeat: this.createShine, repeat: -1, repeatDelay: 6})



    this.anim = gsap.timeline({paused:true})
    for(let [i,a] of this.DOM.els.entries()){

      

      //CENTER
      // if(i == 0){

      //   this.anim.fromTo(a,{y:0+'rem'},{y:-5.6+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:-5.6+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      
      // }
      // if(i == 1){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:-2.8+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:-2.8+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      
      // }
      // if(i == 2){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:0+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
        
      // }
      // if(i == 3){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:2.8+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
        
      // }
      // if(i == 4){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:5.6+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:5.6+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
        
      // }

      // let multi = 2*(i-1)
      let multi = 0
      if(i == 0){

        // this.anim.fromTo(a,{y:0+'rem'},{y:-5.6+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
        // this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:-5.6+'rem',z:0+'vw',duration:1,delay:1,ease:'none'},0)
      
      }
      // if(i == 1){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+'rem',duration:1,delay:1,ease:'none'},0)
      
      // }
      // if(i == 2){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
        
      // }
      // if(i == 3){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
        
      // }
      // if(i == 4){
      //   this.anim.fromTo(a,{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
      //   this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'none'},0)
        
      // }

      if(i == 1){
        this.anim.fromTo(a,{y:0+'rem'},{y:4.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        // this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
      
      }
      if(i == 2){
        this.anim.fromTo(a,{y:0+'rem'},{y:9.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        // this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        
      }
      if(i == 3){
        this.anim.fromTo(a,{y:0+'rem'},{y:14.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        // this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        
      }
      if(i == 4){
        this.anim.fromTo(a,{y:0+'rem'},{y:19.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        // this.anim.fromTo(this.DOM.ttips[i],{y:0+'rem'},{y:2.8+multi+'rem',duration:1,delay:1,ease:'Power2.inOut'},0)
        
      }


      this.anim.timeScale(1.6)
    }


    if(this.touch == 1){
      this.anim.progress(0)
      this.isupdate = 0
    }

  }

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        
        this.stop()
      }

    return vis

  }
  
  start(){
    if(this.active == 1){
      return false
    }
    this.active = 1
    
    this.repanim.play()
  }
  
  stop(){
    if(this.active == 0){
      return false
    }
    this.repanim.pause()
    this.repanim.progress(0)
    
    this.active = 0

   

  }

  async switchMdl(state = 0,pos=-1){
    if(state == 0){
      //CERRAR
    
      this.DOM.ttips[this.modalst].classList.remove('act')
      this.brganim.reverse()
    
      await window.timeout(600)
      this.mdlBox.classList.remove('act')


    }
    else{
      this.modalst = pos
      //ABRIR
      this.mdlBox.querySelector('.mod_h_els').remove()
      let cont = this.DOM.nfo[pos].querySelector('.mod_h_els').cloneNode(true)

      this.mdlBox.querySelector('.mod_h').insertAdjacentElement('afterbegin',cont)

      



      this.mdlBox.classList.add('act')

      await window.timeout(1000)
      this.DOM.ttips[this.modalst].classList.add('act')
      this.brganim.play()
    }



  }

  initEvents(){
    
    if(this.touch == 0){


      for(let[i,a] of this.DOM.els.entries()){
        
        a.onmouseenter = () =>{
          this.DOM.names[i].classList.add('act')
        }
        a.onmouseleave = () =>{
          this.DOM.names[i].classList.remove('act')
        }

      }

    }
  }
  removeEvents(){

  }

  

  update(pos){

    // this.animstick.current = pos - this.animstick.start
    // //CLAMP
    // this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    // this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
   
    // this.anim.progress(this.animstick.prog)
  }

  onResize(){

    
    this.w = window.innerWidth
    this.h = window.innerHeight

    // this.animstick.start =  (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    // this.animstick.limit = (this.DOM.el.clientHeight - this.h).toFixed(0)
    
    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    // if(this.anim){
    //   this.anim.kill()
    //   this.create()
    //   this.update(window.scrollY)
    // }


  }
  


  
}
