'use strict';

import './index.scss'


export default class {
  constructor(el){
    this.DOM = {
      el: el,
    }
    this.DOM.npt =  this.DOM.el.querySelector(".Afield_npt")
    
    this.initEvents()
  }
  initEvents() {
    this.clickFn = () =>{
      if(!this.DOM.el.classList.contains('foc')){
        this.DOM.npt.focus()
      }
    }
    
    this.checkField = ()=>{
      
        if(this.DOM.npt.value==''){
          this.DOM.el.classList.remove("fill")
        }
        else{
          this.DOM.el.classList.add("fill")
        }
    }
    
    this.focusFn = ()=>{
        this.DOM.el.classList.remove("err")
        this.DOM.el.classList.remove("ok")
        this.DOM.el.classList.add("foc")
        if(this.DOM.el.querySelector('.Afield_but')){

        }
    }
    this.focusoutFn = ()=>{
      this.DOM.el.classList.remove("foc")

      this.check()
        
    }
    
    // this.DOM.el.addEventListener("click", this.clickFn)
    this.DOM.npt.addEventListener("input", this.checkField)
    this.DOM.npt.addEventListener("focusin", this.focusFn)
    this.DOM.npt.addEventListener("focusout", this.focusoutFn)
    
  }
  check(){
    if(this.DOM.el.classList.contains('Afield-not')){
      return false
    }
    if ("" == this.DOM.npt.value){
      this.DOM.el.classList.add("err")

    }
    else{
      if(this.DOM.el.classList.contains("Afield-email")){
        if(this.DOM.npt.value.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i)){
  
          this.DOM.el.classList.remove("err")
          this.DOM.el.classList.add("ok")
        }
        else{
          this.DOM.el.classList.add("err")
          this.DOM.el.classList.remove("ok")

        }
  
      }
      else if(this.DOM.el.classList.contains("Afield-num")){
        if(this.DOM.npt.value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)){
  
          this.DOM.el.classList.remove("err")
          this.DOM.el.classList.add("ok")
        }
        else{
          this.DOM.el.classList.add("err")
          this.DOM.el.classList.remove("ok")

        }
  
      }
      else{
        this.DOM.el.classList.remove("err")
        this.DOM.el.classList.add("ok")
      }
      
    }
    
    
  }
  clear(){
      this.DOM.el.classList.remove("foc")
      this.DOM.el.classList.remove("fill")
      this.DOM.el.classList.remove("err")
      this.DOM.el.classList.remove("ok")
      this.DOM.npt.value=''
  }
  removeEvents() {
      this.DOM.npt.removeEventListener("input", this.checkField)
      this.DOM.npt.removeEventListener("focusin", this.focusFn)
      this.DOM.npt.removeEventListener("focusout", this.focusoutFn)
  }
}
