//READY
export async function getReady() {
  this.cleanP();
  this.cleanWysi();
  await this.startComps();
}

//INTRO
export async function show() {
  this.showIos();
  // this.onResize()
  await this.timeout(1);
}

export async function start(val = 0) {
  this.isVisible = 1;
  let result = await this.animIntro(val);
  this.callIos();
  return result;
}

export async function animIntro() {
  // Anim opac
}
//OUT
export async function animOut() {
  if (this.main.isTouch) {
    this.DOM.el.classList.add("isGone");
  }
  // Anim opac
}

export async function hide() {
  this.isVisible = 0;
  this.stopComps();
}
