//VIEWS
//🟠🔴🔵🟢🟣🟡⚪⚫🟤
import Home from "/views👁️👁️👁️/⚪Home/home.js";
import Contact from "/views👁️👁️👁️/🔵Contact/contact.js";
import Uikit from "/views👁️👁️👁️/⚙️Uikit/uikit.js";
import Error from "/views👁️👁️👁️/🚫Error/error.js";
import Legal from "/views👁️👁️👁️/📄Legal/legal.js";
import About from "/views👁️👁️👁️/🟢About/about.js";
import Design from "/views👁️👁️👁️/🟣Design/design.js";
import Careers from "/views👁️👁️👁️/🟠Careers/careers.js";
// import Calendar from '/views👁️👁️👁️/📅Calendar/calendar.js'
import Blog from "/views👁️👁️👁️/📚Blog/blog.js";
import Post from "/views👁️👁️👁️/📖Post/post.js";

import "/views👁️👁️👁️/⚪Home/styles.js";
import "/views👁️👁️👁️/🟢About/styles.js";
import "/views👁️👁️👁️/⚙️Uikit/styles.js";
import "/views👁️👁️👁️/🚫Error/styles.js";
import "/views👁️👁️👁️/📄Legal/styles.js";
import "/views👁️👁️👁️/🔵Contact/styles.js";
import "/views👁️👁️👁️/🟣Design/styles.js";
import "/views👁️👁️👁️/🟠Careers/styles.js";
// import'/views👁️👁️👁️/📅Calendar/styles.js'
import "/views👁️👁️👁️/📚Blog/styles.js";
import "/views👁️👁️👁️/📖Post/styles.js";

// import Uikit from '/views👁️👁️👁️/Uikit⚙️'
// import Project from '/views👁️👁️👁️/Project🟢'
// import Contact from '/views👁️👁️👁️/Contact🔵'

// import Legal from '/views👁️👁️👁️/🔗Legal'

export function createViews() {
  this.pages = new Map();
  this.pages.set("home", new Home(this.main));
  this.pages.set("error", new Error(this.main));
  this.pages.set("uikit", new Uikit(this.main));
  this.pages.set("legal", new Legal(this.main));
  this.pages.set("about", new About(this.main));
  this.pages.set("contact", new Contact(this.main));
  this.pages.set("design", new Design(this.main));
  this.pages.set("careers", new Careers(this.main));
  // this.pages.set('calendar', new Calendar(this.main))
  this.pages.set("blog", new Blog(this.main));
  this.pages.set("post", new Post(this.main));
}
