import './index.scss'

class Loader {
	constructor(main, temp, device, lenis) {
		this.main = main
		this.counter = 0
		this.index = 0
		this.temp = {init: temp, pop: '<div class="pop"><div class="pop_grad"></div></div>'}
		this.lenis = lenis
	}
	async create() {
		document.querySelector('body').insertAdjacentHTML('afterbegin', this.temp.init)

		this.DOM = {
			el: document.documentElement.querySelector('.loader'),
		}

		this.createAnim()
	}

	createAnim() {
		this.anim = gsap.timeline({paused: true, yoyo: true})
		this.anim.to(this.DOM.el.querySelector('.loader_box'), {opacity: 1, duration: 0.4, ease: 'power2.inOut'}, 0)
		// this.anim.fromTo(document.querySelector('.loader_box'),{opacity:1},{opacity:0.5,duration:1,ease:Power2.easeIn},.4)
		this.anim.to(this.DOM.el.querySelector('.loader_box_im'), {x: 291 + 'px', ease: 'power2.inOut', duration: 8}, 0.4)
		this.anim.to(this.DOM.el.querySelector('.textshadow'), {x: 291 + 'px', ease: 'power2.inOut', duration: 8, delay: 1}, 0.4)
	}

	async hide() {}
	async show() {}
	async start() {
		// await gsap.to(document.querySelector('.mousebg'),{opacity:1,duration:.45,delay:.6})
		this.anim.play()
	}

	async showPop(page, y) {
		if (this.main.isTouch == 1) {
		} else {
			page.style.position = 'absolute'

			page.style.setProperty('--t1', this.lenis.animatedScroll + 'px')
			page.style.setProperty('--t2', this.lenis.animatedScroll + this.main.screen.h + 'px')
		}

		page.classList.add('goOut')

		document.querySelector('body').insertAdjacentHTML('afterbegin', this.temp.pop)

		let pop = document.querySelector('.pop')

		let anim = gsap.timeline({paused: true}).to('.nav', {opacity: 0, duration: 0.8, ease: 'power2.inOut'}, 0)

		if (this.main.isTouch == 1) {
			anim.to(page, {opacity: 0, duration: 0.6, ease: 'linear'}, 0).set(pop, {opacity: 0}, 0)
		} else {
			anim
				.to(pop, {'--gradsize': 50 + '%', '--gradop': 1, '--y': -50 + '%', duration: 1, ease: 'linear'}, 0)
				.to(page, {'--t2': '-=' + window.innerHeight + 'px', duration: 0.6, ease: 'linear'}, 1)
				.to(pop, {bottom: 100 + '%', duration: 0.6, ease: 'linear'}, 1)
		}

		this.DOM.el = pop
		await anim.play()
	}

	async hidePop() {
		this.DOM.el.remove()
		gsap.to('.nav', {opacity: 1, duration: 0.8, ease: 'power2.inOut'})
	}

	async hideIntro() {
		// if(this.tlstart.progress()!=1){

		// gsap.to(this.DOM.numtext,{yPercent:this.numtextpos,duration:1,ease:Power3.easeInOut})
		// gsap.to(this.DOM.num,{yPercent:100,y:-100+'vh',x:100+'vw',xPercent:-100,duration:1,ease:Power3.easeInOut})

		// await gsap.to(this.DOM.prog,{scaleX:1,duration:1})

		// }
		this.anim.pause()
		// gsap.fromTo(document.querySelector('.mousebg_el'),{scale:.6,yoyo:true,duration:.6,ease:'power2.inOut'})

		if (this.main.isTouch == 1) {
			gsap.to(this.DOM.el.querySelector('.loader_box_im'), {x: 291 + 'px', ease: 'power2.inOut', duration: 0.6})
			gsap.to(this.DOM.el.querySelector('.textshadow'), {
				x: 291 + 'px',
				ease: 'power2.inOut',
				duration: 0.45,
				delay: 0.15,
			})

			await gsap.to(this.DOM.el, {opacity: 0, duration: 0.5, ease: 'power2.inOut'})
		} else {
			gsap.to(this.DOM.el.querySelector('.loader_box_im'), {x: 291 + 'px', ease: 'power2.inOut', duration: 0.6})
			gsap.to(this.DOM.el.querySelector('.textshadow'), {
				x: 291 + 'px',
				ease: 'power2.inOut',
				duration: 0.45,
				delay: 0.15,
			})

			await gsap.to(this.DOM.el, {opacity: 0, duration: 0.5, ease: 'power2.inOut'})
		}

		this.DOM.el.remove()
	}
}

export default Loader
