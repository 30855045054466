
import './index.scss'

export default class {
  constructor (obj,device,touch) {
 
    obj.el.parentNode.setAttribute('id','stck'+obj.pos)

    if(obj.el.dataset.prior){
      this.prior = parseInt(obj.el.dataset.prior)
    }

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      parent:document.querySelector('#stck'+obj.pos),
    }


    this.active = 0
    this.isupdate = 1
    
    
    this.h = window.innerHeight
    
    this.create()
  }
  
  create(){


    this.sliderpos = -1

    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      total:0,
    }

    //ANIM
    this.DOM.images = this.DOM.parent.querySelectorAll('.im')
    this.anim = gsap.timeline({paused:true})
    this.anim.fromTo(this.DOM.parent.querySelector('.cnt_el'),
    {'--o':0,'--y':-50+'vh','--p':50+'%',
    
    '--rX':0+'deg','--blur':6+'px'},
    {'--o':1,'--y':0+'vh','--p':0+'%',
    '--rY':0+'deg',
    '--rX':0+'deg',
    '--blur':0+'px',
    ease:'none',
    duration:.8,
    onComplete:()=>{
      this.DOM.parent.querySelector('.cnt_el').style.setProperty('--blur','')
    }}
    ,0)

    this.anim.fromTo(this.DOM.parent.querySelector('.cnt_el'),
    {'--o2':0},
    {'--o2':1,
    duration:.5}
    ,.5)
    for(let [i,a] of this.DOM.images.entries()){

      if(i == 0){
        // this.anim.to(a,{y:150+'vh',duration:4},0)
        this.anim.to(a,{y:-100+'vh',z:0+'vw',yPercent:-100,duration:2,ease:'none'},2)
        this.anim.fromTo(a,{'--blur':4+'px'},{'--blur':0+'px',duration:1,ease:'none'},2)
      }
      if(i == 1){
        this.anim.to(a,{y:-100+'vh',z:0+'vw',yPercent:-100,duration:2,ease:'none'},0.1)
        this.anim.fromTo(a,{'--blur':4+'px'},{'--blur':0+'px',duration:1,ease:'none'},0.1)
        
      }
      if(i == 2){
        this.anim.to(a,{y:-100+'vh',z:0+'vw',yPercent:-100,duration:3.6,ease:'none'},.4)
        this.anim.fromTo(a,{'--blur':4+'px'},{'--blur':0+'px',duration:1,ease:'none'},.4)
        
      }
      if(i == 3){
        this.anim.to(a,{y:-100+'vh',z:0+'vw',yPercent:-100,duration:2,ease:'none'},1)
        this.anim.fromTo(a,{'--blur':4+'px'},{'--blur':0+'px',duration:1,ease:'none'},1)
        
      }
      if(i == 4){
        this.anim.to(a,{y:-100+'vh',z:0+'vw',yPercent:-100,duration:3.2,ease:'none'},1.8)
        this.anim.fromTo(a,{'--blur':4+'px'},{'--blur':0+'px',duration:1,ease:'none'},1.8)
        
      }


    }



  }

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
        vis = true
        this.start()

      }
      else{
        
        this.stop()
      }

    return vis

  }
  
  start(){

    this.active = 1

  }
  
  stop(){
    
    this.active = 0

   

  }

  initEvents(){
   
  }
  removeEvents(){

  }

  

  update(pos){

    this.animstick.current = pos - this.animstick.start
    //CLAMP
    this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
   
    this.anim.progress(this.animstick.prog)
  }

  onResize(){

    
    this.w = window.innerWidth
    this.h = window.innerHeight

    this.animstick.start =  (this.DOM.el.getBoundingClientRect().y  - this.h + window.scrollY).toFixed(0)
    this.animstick.limit = (this.DOM.el.clientHeight + this.h ).toFixed(0)
    
    //* El kill y el create solo si el anim está hecho en absoluto y no con porcentaje
    //* Importante que todos los elementos que vayas a matar tengan un fromTo
    // if(this.anim){
    //   this.anim.kill()
    //   this.create()
    //   this.update(window.scrollY)
    // }


  }
  


  
}
